import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import { baseStore } from '../../../store';
import { CurrencyDefinition, FeeDto, WalletAsset } from '../../../typings';
import * as ratesApi from '../../../api/rates';
import * as walletApi from '../../../api/wallets';
import * as withdrawalApi from '../../../api/withdrawal';
import { formatCurrency, handleRequestErrors, normaliseAmount } from '../../../utils';
import { ConditionalElement } from '../ConditionalElement';
import { Button } from '../html/Button';
import { Input } from '../html/Input';
import { Security2faDialog } from '../2FADialog';
import { Modal } from '../Modal';
import { setWalletAssets } from '../../../actions/wallets';
import { applyFee } from '../../../utils/math';
import { DropdownInput } from '../html/DropdownInput';
import { StableCoins } from '../../../constants';
import config from '../../../config';
import Gap from '../Gap';
import './styles.scss';
import { FiatWithdrawalDialog } from './FiatWithdrawal';

interface WithdrawalProps {
  assetId: string;
  currency: CurrencyDefinition;
}

export const WithdrawalDialog = (props: WithdrawalProps) => {
  if (props.currency.type === 'FIAT') {
    return <FiatWithdrawalDialog {...props} />;
  }

  const { rates, wallets } = baseStore.getState();
  const [preview, setPreview] = useState(false);
  const [currency, setCurrency] = useState<CurrencyDefinition>();
  const [sourceCurrency, setSourceCurrency] = useState<{ label: string; value: string }>();
  const [sourceWallet, setSourceWallet] = useState<WalletAsset>();
  const [address, setAddress] = useState('');
  const [memo, setMemo] = useState('');
  const [requiresMemo, setRequiresMemo] = useState<boolean>(false);
  const [amount, setAmount] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [opFee, setOpFee] = useState<FeeDto>();
  const [balanceErrorMessage, setBalanceErrorMessage] = useState('');
  const [addressErrorMessage, setAddressErrorMessage] = useState('');
  const [sourceCurrencyOptions, setSourceCurrencyOptions] = useState<any[]>([]);
  const [network, setNetwork] = useState<any>();
  const [networkOptions, setNetworkOptions] = useState<any[]>([]);
  const [fees, setFees] = useState<{ [x: string]: FeeDto[] }>({});

  useEffect(() => {
    const curr = rates.currencies.find(({ code }) => code === props.currency.code);
    const currOptions = wallets!
      .filter(
        ({ currency }) =>
          currency.type === curr?.type &&
          (currency.activities.includes('WITHDRAW') || currency.networks?.some((network) => network.activities.includes('WITHDRAW'))),
      )
      .map((wallet) => ({ label: wallet.currency.name, value: wallet.currency.code }));

    setCurrency(curr);
    setSourceCurrencyOptions(currOptions);
    setSourceCurrency(currOptions.find((option) => option.value === props.currency?.code));
    setSourceWallet(wallets?.find((wallet) => wallet.id === props.assetId));
  }, [props.currency.code]);

  useEffect(() => {
    if (!currency) {
      return;
    }

    const networkOpts = [
      // prettier-ignore
      currency.activities.includes('WITHDRAW') ? {
        label: currency.network,
        value: currency.network,
      } : null,
      ...currency.networks.map(({ network }) => ({
        label: network,
        value: network,
      })),
    ].filter(Boolean);

    const defaultNetwork = networkOpts[0];

    setNetworkOptions(networkOpts);
    setNetwork(defaultNetwork);

    if (!fees[currency.code]) {
      ratesApi
        .getFee('withdrawal', currency.code, currency.code)
        .then((res) => {
          setFees({
            ...fees,
            [currency.code]: res.fees,
          });

          const newOpFee = res.fees.find(
            (fee) => fee.targetCurrency === currency.code && fee.targetCurrency === currency.code && fee.network === defaultNetwork?.value,
          );

          if (newOpFee) {
            const newFeeAmount = applyFee(amount, newOpFee);
            setOpFee(newOpFee);
            setFeeAmount(newFeeAmount);
          }
        })
        .catch(handleRequestErrors);
    }
  }, [currency]);

  useEffect(() => {
    const curr = rates.currencies.find(({ code }) => code === sourceCurrency?.value);

    setCurrency(curr);
    setSourceWallet(wallets?.find((wallet) => wallet.currency.code === curr?.code));
  }, [sourceCurrency]);

  useEffect(() => {
    setBalanceErrorMessage(amount + feeAmount > sourceWallet?.balance! ? 'Insufficient funds available' : '');
  }, [feeAmount, amount]);

  useEffect(() => {
    let fee = 0;

    const methodFee = fees[currency?.code!]?.find(
      (f) => f.sourceCurrency === currency?.code && f.targetCurrency === currency.code && f.network === network?.value,
    );

    if (methodFee) {
      fee = applyFee(amount, methodFee);
      setOpFee(methodFee);
    }

    setFeeAmount(fee);
  }, [amount, network?.value]);

  useEffect(() => {
    const memoIsRequired =
      (currency?.network === network?.value && currency?.requiresMemo) ||
      currency?.networks?.find((curr) => curr.network === network?.value)?.requiresMemo ||
      false;

    setRequiresMemo(memoIsRequired);

    if (config.variant !== 'PRODUCTION') {
      return;
    }

    if (network?.value) {
      // prettier-ignore
      const addressRegex = currency?.network === network?.value ? currency?.addressRegex : currency?.networks.find((ntwk) => ntwk.network === network?.value)?.addressRegex;

      setAddressErrorMessage(
        address && addressRegex && !RegExp(addressRegex).test(address) ? 'The wallet address you have entered is invalid.' : '',
      );
    }
  }, [currency, address, network?.value]);

  const executeWithdrawal = () => {
    Modal.Open(
      <Security2faDialog
        operation="ASSET_WITHDRAWAL"
        handleSecurity={async (security, callback) => {
          withdrawalApi
            .createTransaction({
              assetId: sourceWallet?.id!,
              methodId: 'CRYPTO',
              amount,
              currency: currency?.code!,
              recipient: {
                type: 'CRYPTO',
                currency: currency?.code!,
                accountNumber: address,
                network: network?.value,
                memo,
              },
              memo,
              security,
            })
            .then(() => {
              Modal.Alert(
                'Withdrawal Success',
                `You successfully withdrawn ${formatCurrency(currency?.type!, amount, sourceCurrency?.value!, currency?.decimals!)}`,
              );
              setTimeout(() => walletApi.getWallets().then((res) => baseStore.dispatch(setWalletAssets(res.assets))), 1500);
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );
  };

  // prettier-ignore
  const withdrawalDisabled = !!balanceErrorMessage || !!addressErrorMessage || !amount || !sourceCurrency || !address || (requiresMemo && !memo);

  return preview ? (
    <>
      <div className="head">
        <h4>Withdraw {currency?.name}</h4>
      </div>

      <div className="content">
        <div className="withdrawal-info-preview">
          <div className="info">
            <div className="left">Amount</div>
            <div className="right">{formatCurrency(currency?.type!, amount, sourceCurrency?.value!, sourceWallet?.currency.decimals)}</div>
          </div>

          <div className="info">
            <div className="left">Fee</div>
            <div className="right">
              {formatCurrency(currency?.type!, feeAmount, sourceCurrency?.value!, sourceWallet?.currency.decimals)}
            </div>
          </div>

          <div className="info">
            <div className="left">Address</div>
            <div className="right" style={{ maxWidth: '70%' }}>
              {address}
            </div>
          </div>

          <div className="info">
            <div className="left">Network</div>
            <div className="right">{network?.value}</div>
          </div>

          {memo ? (
            <div className="info">
              <div className="left">Memo</div>
              <div className="right">{memo}</div>
            </div>
          ) : null}
        </div>

        <Gap v={1} />

        <Button className="btn btn-primary full-width" text="Continue" onClick={() => executeWithdrawal()} />
      </div>
    </>
  ) : (
    <>
      <div className="head">
        <h4>Withdraw {currency?.name}</h4>
      </div>

      <div className="content">
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            setPreview(true);
          }}
        >
          <label htmlFor="w-currency">Select a Currency</label>
          <Gap v={0.5} />
          <Select
            id="w-currency"
            name="sourceCurrency"
            options={sourceCurrencyOptions}
            onChange={(ev: any) => {
              setAmount(0);
              setSourceCurrency(ev);
            }}
            value={sourceCurrency}
            classNamePrefix="custom-select"
          />
          {sourceCurrency && currency ? (
            <small className="text-muted">
              Available: {formatCurrency(currency?.type!, sourceWallet?.balance || 0, sourceCurrency?.value!, currency?.decimals!)}{' '}
            </small>
          ) : null}

          <Gap v={1} />

          {currency ? (
            <>
              <div className="row">
                <div className="col">
                  <label>Enter Amount</label>
                </div>
                <div className="col-auto">
                  <a
                    className="cursor-pointer"
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      const balance = sourceWallet?.balance!;
                      const fee = opFee ? applyFee(balance, opFee) : 0;
                      const newAmount = normaliseAmount(sourceWallet?.currency.withdrawDecimals!, balance - fee);
                      setAmount(newAmount <= 0 ? 0 : newAmount);
                    }}
                  >
                    Use Max
                  </a>
                </div>
              </div>
              <Gap v={0.5} />
              <Cleave
                required
                name="amount"
                className="form-control font-weight-bold"
                placeholder={`E.g ${formatCurrency(currency.type, 0, sourceCurrency?.value!, sourceWallet?.currency.decimals)}`}
                autoComplete="off"
                value={amount}
                options={{
                  numeral: true,
                  numeralDecimalScale: StableCoins.includes(currency.code) ? 2 : currency.decimals,
                }}
                onChange={(ev) => setAmount(Number(ev.target.value.replace(/[^\d.-]/g, '')))}
              />
              <Gap v={0.5} />
              {currency ? (
                <span>Fee: {formatCurrency(currency?.type, feeAmount, sourceCurrency?.value!, sourceWallet?.currency.decimals)}</span>
              ) : null}

              <Gap v={1} />
            </>
          ) : null}

          <DropdownInput
            required
            autoComplete="off"
            type="text"
            name="address"
            id="address-input"
            label="Recipient Address"
            placeholder="Enter Address"
            onChange={(ev) => setAddress(ev.target.value)}
            gapbottom={1}
          />

          <label htmlFor="w-currency">Select a Network</label>
          <Gap v={0.5} />
          <Select
            isMulti={false}
            id="w-network"
            name="network"
            placeholder="Select a Network"
            options={networkOptions}
            controlShouldRenderValue={true}
            value={network}
            onChange={(ev) => setNetwork(ev)}
          />
          <Gap v={1} />

          {
            // prettier-ignore
            requiresMemo ? (
              <Input
                required={true}
                autoComplete="off"
                id="w-memo"
                type="text"
                name="memo"
                label="Memo"
                placeholder="Memo. E.g Settlement"
                onChange={(ev) => setMemo(ev.target.value)}
                gapbottom={1}
              />
            ) : null
          }

          <ConditionalElement
            condition={!!balanceErrorMessage || !!addressErrorMessage}
            element={<div className="color-fd0000 text-center">{balanceErrorMessage || addressErrorMessage}</div>}
          />

          <Gap v={1} />
          <Button disabled={withdrawalDisabled} type="submit" className="btn btn-primary full-width" text="Continue" />
        </form>
      </div>
    </>
  );
};
