import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { HiPlusSm } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { handleRequestErrors } from '../../../utils';
import * as depositApi from '../../../api/deposit';
import * as withdrawalApi from '../../../api/withdrawal';
import { setAccounts, addAccount, removeAccount } from '../../../actions/withdrawal';
import { ConditionalElement } from '../../components/ConditionalElement';
import { DepositAccountDto, ReducerStates, WithdrawalAccountDto } from '../../../typings';
import Gap from '../../components/Gap';
import { AddBankAccountModal } from './Modals';
import { Modal } from '../../components/Modal';
import '../settings.scss';
import './styles.scss';
import { Security2faDialog } from '../../components/2FADialog';

export const BankAccountSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [depositAccounts, setDepositAccounts] = useState<DepositAccountDto[]>([]);
  const { withdrawal } = useSelector((state: ReducerStates) => state);

  useEffect(() => {
    withdrawalApi
      .getAccounts('BANK')
      .then((data) => dispatch(setAccounts(data.accounts)))
      .catch((err) => handleRequestErrors(err));
  }, []);

  useEffect(() => {
    depositApi
      .getAccounts()
      .then((res) => setDepositAccounts(res.accounts))
      .catch((err) => handleRequestErrors(err));
  }, []);

  const withdrawalAccounts = withdrawal.accounts.filter((account) => account.type === 'BANK' || account.type === 'MOMO');

  return (
    <>
      <Helmet title="Settings | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Bank Accounts</h6>
                      </div>

                      <div className="col-auto">
                        <button
                          className="btn btn-primary btn-float-mobile"
                          onClick={() => {
                            Modal.Open(
                              <AddBankAccountModal
                                type="WITHDRAWAL"
                                onAccountAdd={(type, account) => {
                                  dispatch(addAccount(account as WithdrawalAccountDto));
                                }}
                              />,
                              {
                                customClass: {
                                  container: 'modal-mobile-fullscreen',
                                  popup: 'max-w-400x',
                                },
                              },
                            );
                          }}
                        >
                          <span className="text hidden-768px">Add Account</span>
                          <span className="icon visible-768px">
                            <HiPlusSm />
                          </span>
                        </button>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="table-basic table-fiat-accounts">
                      <table>
                        <thead className="hidden-768px">
                          <tr>
                            <th className="">Type</th>
                            <th>Bank</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {[...depositAccounts, ...withdrawalAccounts]
                            .slice()
                            .sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime())
                            .map((account) => {
                              const isDeposit = depositAccounts.find((acct) => acct.id === account.id);

                              return (
                                <tr key={account.id}>
                                  <td className="hidden-768px">{account.type}</td>
                                  <td className="hidden-768px">
                                    <div>{account.bankName || account.network}</div>
                                  </td>
                                  <td className="hidden-768px">
                                    <div>{account.accountName}</div>
                                  </td>
                                  <td>
                                    {account.accountNumber} <span className="type-tag">{isDeposit ? 'Deposit' : 'Withdrawal'}</span>
                                    <div className="truncate-text visible-768px">{account.accountName}</div>
                                    <div className="truncate-text visible-768px">{account.bankName}</div>
                                  </td>
                                  <td>
                                    <span
                                      className="btn btn-danger delete"
                                      onClick={() => {
                                        const isDepositAccount = depositAccounts.find((acct) => acct.id === account.id);

                                        Modal.Open(
                                          <Security2faDialog
                                            operation={isDepositAccount ? 'DEPOSIT_ACCOUNT_REMOVE' : 'WITHDRAWAL_ACCOUNT_REMOVE'}
                                            handleSecurity={async (security, callback) => {
                                              try {
                                                if (isDepositAccount) {
                                                  await depositApi
                                                    .removeAccount(account.id!, security)
                                                    .then(() =>
                                                      setDepositAccounts(depositAccounts.filter((acct) => acct.id !== account.id)),
                                                    );
                                                } else {
                                                  await withdrawalApi
                                                    .removeAccount(account.id!, security)
                                                    .then(() => dispatch(removeAccount(account.id!)));
                                                }

                                                Modal.CloseAll();
                                              } catch (err) {
                                                callback(null, err);
                                                handleRequestErrors(err);
                                              }
                                            }}
                                          />,
                                          {
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            customClass: {
                                              container: 'modal-mobile-fullscreen',
                                              popup: 'max-w-350x',
                                            },
                                          },
                                        );
                                      }}
                                    >
                                      ✕
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>

                      <ConditionalElement
                        condition={(withdrawalAccounts.length || depositAccounts.length) === 0}
                        element={
                          <div className="no-result">
                            <h6>You don't have any saved accounts</h6>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
