import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ToggleSwitch } from '../html/ToggleSwitch';
import RcDropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { SlArrowRight } from 'react-icons/sl';
import { CurrencyDefinition, ReducerStates, WalletAsset } from '../../../typings';
import { useSelector } from 'react-redux';
import { formatCurrency, normaliseAmount } from '../../../utils';
import { Modal } from '../Modal';
import { Input } from '../html/Input';
import { ConditionalElement } from '../ConditionalElement';
import { DepositDialog } from '../DepositDialog';
import { ExchangeDialog } from '../ExchangeDialog';
import { convertAssetBalance } from '../../../utils/math';
import { WithdrawalDialog } from '../WithdrawalDialog';
import { StableCoins, USD, USDT } from '../../../constants';
import Gap from '../Gap';
import './styles.scss';
import { TransferDialog } from '../TransferDialog';
import config from '../../../config';
import { CurrencyActivityDialog } from '../CurrencyActivityDialog';

interface AssetsListProps {
  title?: string;
  limit?: number;
  paginated?: {
    size: number;
    totalItems: number;
  };
}

interface AssetRowProps {
  asset: WalletAsset;
}

const AssetRow = (props: AssetRowProps) => {
  const { id, type, currency, balance } = props.asset;
  const { rates, user } = useSelector((state: ReducerStates) => state);
  const [showDetails, setShowDetails] = useState(false);

  const buyActivity = currency.activities.includes('BUY');
  const sellActivity = currency.activities.includes('SELL');
  const transferActivity = currency.activities.includes('TRANSFER');

  let depositActivity =
    currency.activities?.includes('DEPOSIT') || currency.networks?.some((network) => network.activities.includes('DEPOSIT'));
  let withdrawalActivity =
    currency.activities?.includes('WITHDRAW') || currency.networks?.some((network) => network.activities.includes('WITHDRAW'));

  if (config.enabledCryptoDeposits === 'false') {
    depositActivity = false;
    withdrawalActivity = false;
  }

  // const localCurrency = kyc.business?.currency || kyc.individual?.currency;
  const defaultCurrency = rates.currencies.find((currency) => currency.code === (user.preferredCurrency || USD));
  const conversionCurrency = defaultCurrency?.code === USD ? USDT : defaultCurrency?.code;

  // prettier-ignore
  const conversion = rates.conversions.find((conv) =>
    currency.type === 'FIAT' ?
      defaultCurrency?.code === USD ?
        conv.fromCurrency === currency.code && conv.toCurrency === conversionCurrency :
        conv.fromCurrency === conversionCurrency && conv.toCurrency === currency.code :
      currency.code === USDT && defaultCurrency?.code === USD ?
        conv.fromCurrency === USD && conv.toCurrency === currency.code :
        conv.fromCurrency === conversionCurrency && conv.toCurrency === currency.code,
  );

  // prettier-ignore
  const balanceConversion = rates.conversions.find((conv) =>
    currency.code === defaultCurrency?.code ?
      conv.fromCurrency === defaultCurrency?.code && conv.toCurrency === USDT :
      currency.code === USDT && defaultCurrency?.code === USD ?
        conv.fromCurrency === USDT && conv.toCurrency === USD :
        conv.fromCurrency === currency.code && conv.toCurrency === (defaultCurrency?.code === USD ? USDT : defaultCurrency?.code),
  );

  const convertedBalance = convertAssetBalance(props.asset, balanceConversion!);

  // prettier-ignore
  const altAssetBalance =
    currency.code === USD && defaultCurrency?.code === USD ?
      formatCurrency('FIAT', props.asset.balance, USD, 2) :
      formatCurrency('FIAT', convertedBalance, currency.code === user.preferredCurrency ? USD : defaultCurrency?.code!, 2);

  // prettier-ignore
  let assetPrice =
    currency.code === defaultCurrency?.code ?
      formatCurrency(defaultCurrency!.type, 1, defaultCurrency!.code, defaultCurrency?.decimals) : conversion ?
        formatCurrency(defaultCurrency!.type, 1/conversion.rate, defaultCurrency!.code, defaultCurrency?.decimals) : '-';

  // Special conversion cases
  if (conversion && currency.code === 'NGN' && defaultCurrency?.code === 'GHS') {
    // show more decimals
    assetPrice = `GHS ${normaliseAmount(5, 1 / conversion.rate)}`;
  }

  const percentageChange = conversion ? conversion.percentageChange : '0';
  const isNegativeChange = conversion?.percentageChange?.toString().startsWith('-');
  const isNoChange = conversion?.percentageChange === 0;

  const openDepositDialog = (activity: boolean, assetId: string, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Deposit Disabled', `${currency.code} deposits are currently disabled.`);
    }

    Modal.Open(<DepositDialog assetId={assetId} currency={currency} />, {
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: `deposit-dialog-popup ${currency.type === 'CRYPTO' ? 'max-w-400x' : ''}`,
        htmlContainer: 'deposit-dialog-container',
      },
    });
  };

  const openWithdrawalDialog = (activity: boolean, assetId: string, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Withdrawal Disabled', `${currency.code} withdrawals are currently disabled.`);
    }

    Modal.Open(<WithdrawalDialog assetId={assetId} currency={currency} />, {
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: 'max-w-400x withdrawal-dialog-popup',
        htmlContainer: 'withdrawal-dialog-container',
      },
    });
  };

  const openExchangeDialog = (activity: boolean, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Exchange Disabled', `${currency.code} exchange is currently disabled.`);
    }

    Modal.Open(<ExchangeDialog currency={currency} />, {
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: 'max-w-500x',
      },
    });
  };

  const openTransferDialog = (activity: boolean, currency: CurrencyDefinition) => {
    if (!activity) {
      Modal.Alert('Transfer Disabled', `${currency.code} transfer is currently disabled.`);
    }

    Modal.Open(<TransferDialog currency={currency} />, {
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        container: 'modal-mobile-fullscreen',
        popup: 'max-w-400x',
      },
    });
  };

  const showActivityDialog = () => {
    setShowDetails(!showDetails);
    Modal.Open(<CurrencyActivityDialog assetId={id} currency={currency} />, {
      customClass: {
        popup: 'max-w-400x currency-activity-popup',
        htmlContainer: 'currency-activity-container',
      },
    });
  };

  const isStableCoin = StableCoins.includes(currency.code);

  return (
    <>
      <tr>
        <td className="detail-pointer detail-activity">
          <div className="illus-wrap">
            <div className="illus">
              <img
                src={`https://s3.us-east-2.amazonaws.com/uploads.hostcap.io/files/currencies/${currency.code.toLocaleLowerCase()}.svg`}
                alt=""
              />
            </div>
            <div className="info">
              <div className="truncate-text">{currency.code}</div>
              <div className="truncate-text fw-bold">{currency.name}</div>
            </div>
          </div>

          <span className="clickable" onClick={() => showActivityDialog()}></span>
        </td>

        <td className="detail-pointer detail-activity detail-icon text-end">
          <div>
            <div>{formatCurrency(type, balance, currency.code, currency.decimals)}</div>
            {currency.code === USD && defaultCurrency?.code === USD ? null : <div className="color-9e9e9e text-end">{altAssetBalance}</div>}
          </div>

          <span className="icon">
            <SlArrowRight />
          </span>

          <span className="clickable" onClick={() => showActivityDialog()}></span>
        </td>

        <td className="text-end hidden-768px">{isStableCoin ? formatCurrency('FIAT', 1, 'USD', 2) : assetPrice}</td>

        <td className="text-end hidden-768px">
          {conversion ? (
            <span className={isNegativeChange ? 'negative-change' : isNoChange ? '' : 'positive-change'}>
              {isNegativeChange || isNoChange ? '' : '+'}
              {percentageChange}%
            </span>
          ) : (
            '-'
          )}
        </td>

        <td
          className={`text-end btns-cell ${
            (buyActivity || sellActivity) && depositActivity && withdrawalActivity ? 'hide-exchange-btn' : ''
          }`}
        >
          {buyActivity || sellActivity ? (
            <a
              className="btn btn-primary hidden-870px exchange-btn"
              onClick={() => openExchangeDialog(buyActivity || sellActivity, currency)}
            >
              Exchange
            </a>
          ) : null}

          {depositActivity ? (
            <a className="btn btn-primary hidden-870px" onClick={() => openDepositDialog(depositActivity, id, currency)}>
              Deposit
            </a>
          ) : null}

          {withdrawalActivity ? (
            <a className="btn btn-primary light hidden-870px" onClick={() => openWithdrawalDialog(withdrawalActivity, id, currency)}>
              Withdraw
            </a>
          ) : null}

          {(buyActivity || sellActivity) && !(depositActivity || withdrawalActivity) && transferActivity ? (
            <a className="btn btn-primary light hidden-870px" onClick={() => openTransferDialog(transferActivity, currency)}>
              Transfer
            </a>
          ) : null}

          <div className="trade-dd visible--870px hidden">
            <RcDropdown
              trigger={['click']}
              overlay={
                <Menu>
                  {depositActivity ? (
                    <MenuItem
                      key={`asset-${id}-deposit`}
                      disabled={!depositActivity}
                      onClick={() => openDepositDialog(depositActivity, id, currency)}
                    >
                      Deposit
                    </MenuItem>
                  ) : null}

                  {withdrawalActivity ? (
                    <MenuItem
                      key={`asset-${id}-withdraw`}
                      disabled={!withdrawalActivity}
                      onClick={() => openWithdrawalDialog(withdrawalActivity, id, currency)}
                    >
                      Withdraw
                    </MenuItem>
                  ) : null}

                  {buyActivity || sellActivity ? (
                    <MenuItem key={`asset-${id}-exchange`} onClick={() => openExchangeDialog(buyActivity || sellActivity, currency)}>
                      Exchange
                    </MenuItem>
                  ) : null}

                  <MenuItem key={`asset-${id}-transfer`} onClick={() => openTransferDialog(transferActivity, currency)}>
                    Transfer
                  </MenuItem>
                </Menu>
              }
              alignPoint
            >
              <IoEllipsisHorizontalSharp />
            </RcDropdown>
          </div>

          {buyActivity && sellActivity}
        </td>
      </tr>

      {/* <ConditionalElement
        condition={showDetails}
        element={
          <tr className="detailed-view">
            <td colSpan={5}>
              <div className="asset-details">
                <div className="detail">
                  <div className="left">Reserved Balance</div>
                  <div className="right">{formatCurrency(type, reservedBalance - balance, currency.code, currency.decimals)}</div>
                </div>
                <div className="detail">
                  <div className="left">Available Balance</div>
                  <div className="right">{formatCurrency(type, balance, currency.code, currency.decimals)}</div>
                </div>
                <div className="detail">
                  <div className="left">Price</div>
                  <div className="right">{assetPrice}</div>
                </div>
                <div className="detail">
                  <div className="left">24H</div>
                  <div className="right">
                    {conversion ? (
                      <span className={isNegativeChange ? 'negative-change' : isPositiveChange ? 'positive-change' : 'no-change'}>
                        {isPositiveChange ? '+' : isNegativeChange ? '-' : ''}
                        {percentageChange}%
                      </span>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        }
      /> */}
    </>
  );
};

export const AssetsList = (props: AssetsListProps) => {
  const { wallets } = useSelector((state: ReducerStates) => state);
  const [assets, setAssets] = useState<WalletAsset[]>([]);
  const [hideZero, setHideZero] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(props.paginated?.size || 10);
  const [totalItems, setTotalItems] = useState(props.paginated?.totalItems || 0);

  const pageTotal = rows * page > totalItems ? totalItems : rows * page;
  const pages = Math.ceil(totalItems / rows);

  const fiatFirst = (assets: WalletAsset[]): WalletAsset[] => {
    return [
      ...assets.filter((asset) => asset.type === 'FIAT'),
      ...assets.filter((asset) => asset.type === 'CRYPTO').sort((a, b) => b.balance - a.balance),
    ];
  };

  useEffect(() => {
    setAssets(fiatFirst(wallets));
    setTotalItems(wallets.length);
  }, [wallets]);

  useEffect(() => {
    const filteredAssets = wallets.filter((wallet) => (hideZero ? wallet.balance > 0 : true));

    setAssets(fiatFirst(filteredAssets));
    setTotalItems(filteredAssets.length);
    setPage(1);
  }, [hideZero]);

  useEffect(() => {
    const filteredAssets = wallets.filter((wallet) =>
      // prettier-ignore
      searchText ? (wallet.currency.name.toLowerCase().includes(searchText.toLowerCase()) || wallet.currency.code.toLowerCase().includes(searchText.toLowerCase())) : true,
    );

    setAssets(fiatFirst(filteredAssets));
    setTotalItems(filteredAssets.length);
    setPage(1);
  }, [searchText]);

  const nextPage = () => (page < pages ? setPage(page + 1) : void 0);
  const prevPage = () => (page > 1 ? setPage(page - 1) : void 0);

  return (
    <div className="table-advanced">
      <div className="row">
        <div className="col-md" data-vertical_center>
          <h5>{props.title || 'My Assets'}</h5>
        </div>

        <div className="col-md-auto" data-vertical_center>
          <Gap v={1} className="visible-768px" />
          <Input placeholder="Search assets" onChange={(ev) => setSearchText(ev.target.value)} />
        </div>
      </div>

      <Gap v={1} />
      <Gap v={1} className="visible-768px" />

      <div className="table-wrapper table-wrapper-assets">
        <table className="has-details">
          <thead>
            <tr>
              <th>Asset</th>
              <th className="text-end">Balance</th>
              <th className="text-end">Price</th>
              <th className="text-end">24H</th>
              <th className="text-end">
                <div className="hidden-870px">
                  Hide Zero Balances &nbsp;
                  <ToggleSwitch on={hideZero} onSwitch={setHideZero} />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {assets.slice((page - 1) * rows, pageTotal).map((asset) => (
              <AssetRow key={asset.id} asset={asset} />
            ))}
          </tbody>
        </table>

        <ConditionalElement
          condition={assets.length === 0}
          element={
            <div className="no-result">
              <h5>
                {
                  // prettier-ignore
                  hideZero ? 'No asset(s)' :
                  // eslint-disable-next-line quotes
                    "You don't have any asset(s) yet"
                }
              </h5>
            </div>
          }
        />
      </div>

      <Gap v={1} />

      <div className="table-pagination">
        <div className="pagination-wrap">
          <div className="item-total no-caret hidden">
            <Dropdown>
              <Dropdown.Toggle as="span" className="color-4F4F4F">
                Rows per page: &nbsp;&nbsp; {rows}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-custom">
                <ul>
                  {[].map((c) => (
                    <li key={`rows-${c}`} onClick={() => setRows(c)}>
                      {c}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <ConditionalElement
            condition={totalItems > rows}
            element={
              <div className="item-nav">
                <span className="color-4F4F4F">
                  {(page - 1) * rows + 1} - {pageTotal} of {totalItems}
                </span>

                <Gap h={1} />

                <a className="prev" onClick={prevPage}></a>
                <a className="next" onClick={nextPage}></a>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};
