import React, { useState } from 'react';
import { WithdrawalProps } from './common';
import { Stage1 } from './Stage1';
import { Stage2 } from './Stage2';
import { Stage3 } from './Stage3';
import '../styles.scss';
import { WithdrawalRequestDto } from '../../../../typings';

export const FiatWithdrawalDialog = (props: WithdrawalProps) => {
  const [stage, toStage] = useState<'STAGE_1' | 'STAGE_2' | 'STAGE_3'>('STAGE_1');
  const [requestData, setRequestData] = useState<Partial<WithdrawalRequestDto>>({ assetId: props.assetId });

  return (
    <>
      <div style={{ display: stage === 'STAGE_1' ? 'block' : 'none' }}>
        <Stage1
          {...props}
          request={requestData}
          goToNext={() => toStage('STAGE_2')}
          updateRequest={(data) => setRequestData({ ...requestData, ...data })}
        />
      </div>

      <div style={{ display: stage === 'STAGE_2' ? 'block' : 'none' }}>
        <Stage2
          {...props}
          request={requestData}
          goToNext={() => toStage('STAGE_3')}
          goToPrevious={() => toStage('STAGE_1')}
          updateRequest={(data) => setRequestData({ ...requestData, ...data })}
        />
      </div>

      <div style={{ display: stage === 'STAGE_3' ? 'block' : 'none' }}>
        <Stage3
          {...props}
          request={requestData}
          goToPrevious={() => toStage('STAGE_2')}
          updateRequest={(data) => setRequestData({ ...requestData, ...data })}
        />
      </div>
    </>
  );
};
