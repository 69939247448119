import moment from 'moment';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import * as developerApi from '../../../api/developer';
import { formatStatusType, handleRequestErrors } from '../../../utils';
import { WebhookHistoryDto } from '../../../typings';
import { ConditionalElement } from '../../components/ConditionalElement';
import Gap from '../../components/Gap';
import '../settings.scss';
import './styles.scss';
import { route } from '../../../routes';
import { Modal } from '../../components/Modal';
import { CopiableText } from '../../components/CopiableText';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import RcDropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Input } from '../../components/html/Input';
import { subscriptionEventsOptions } from './Webhooks';
import { toast } from 'react-toastify';

export const DeveloperWebhookHistory = () => {
  const navigate = useNavigate();
  const [size] = useState(25);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [loadingPosition, setLoadingPosition] = useState<'LEFT' | 'RIGHT'>();
  const [histories, setHistories] = useState<WebhookHistoryDto[]>([]);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>();
  const [filterToDate, setFilterToDate] = useState<Date | null>();
  const [filterStatus, setFilterStatus] = useState<string | undefined>();
  const [filterEvent, setFilterEvent] = useState<string | undefined>();
  const [filterUrl, setFilterUrl] = useState<string | undefined>();

  const pageTotal = size * page > totalItems ? totalItems : size * page;

  const fetchWebhookHistory = () => {
    setLoading(true);

    developerApi
      // prettier-ignore
      .getWebhookHistories(
        page,
        size,
        filterStatus,
        filterEvent,
        filterUrl?.trim(),
        filterFromDate ? moment(filterFromDate).format('yyyy-MM-DD hh:mm') : undefined,
        filterFromDate ? moment(filterToDate).format('yyyy-MM-DD hh:mm') : undefined,
      )
      .then((res) => {
        setHistories(res.records);
        setTotalItems(res.totalItems);
      })
      .catch(handleRequestErrors)
      .finally(() => {
        setLoading(false);
        setLoadingPosition(undefined);
      });
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    setHistories([]);

    fetchWebhookHistory();
  }, [page, size, filterStatus, filterEvent, filterUrl, filterFromDate, filterToDate]);

  return (
    <>
      <Helmet title="Webhook History | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate(route.settings.developers)}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">
                          Webhook Histories (
                          <span>
                            {pageTotal} of {totalItems}
                          </span>
                          )
                        </h6>
                      </div>
                    </div>

                    <Gap v={1} className="hidden-768px" />

                    <div className="table-filters-wrap">
                      {filterFromDate ? moment(filterFromDate).format('yyyy-MM-DD hh:mm') : undefined}
                      <div className="row">
                        <div className="col-md-12 text-end" data-vertical_center>
                          <div className="text-start mt-4">
                            <div className="row">
                              <div className="col-md-2 col-sm-6 col-xs-6 col-6">
                                <Select
                                  isClearable
                                  placeholder="Status"
                                  options={[
                                    { label: 'IN_PROGRESS', value: 'IN_PROGRESS' },
                                    { label: 'FAILED', value: 'FAILED' },
                                    { label: 'COMPLETED', value: 'COMPLETED' },
                                  ]}
                                  classNamePrefix="custom-select"
                                  onChange={(ev) => setFilterStatus(ev?.value)}
                                />
                              </div>

                              <div className="col-md-3 col-sm-6 col-xs-6 col-6">
                                <Select
                                  isClearable
                                  placeholder="Event"
                                  classNamePrefix="custom-select"
                                  options={subscriptionEventsOptions.map(({ value }) => ({ label: value, value }))}
                                  onChange={(ev) => setFilterEvent(ev?.value)}
                                />
                              </div>

                              <div className="col-md col-sm-12 col-xs-12">
                                <Gap v={1} className="visible-768px" />
                                <Input
                                  type="url"
                                  placeholder="Url: E.g. https://webhook.site"
                                  onChange={(ev) => {
                                    const url = ev.target.value;

                                    setFilterUrl(url.startsWith('http://') || url.startsWith('https://') ? url : undefined);
                                  }}
                                />
                              </div>

                              <div className="col-md col-sm-12 col-xs-12">
                                <div className="row">
                                  <div className="col">
                                    <Gap v={1} className="visible-768px" />
                                    <DatePicker
                                      isClearable
                                      autoComplete="off"
                                      id="sel-date-from"
                                      className="form-control"
                                      showTimeSelect={true}
                                      showTwoColumnMonthYearPicker={true}
                                      selected={filterFromDate}
                                      onChange={setFilterFromDate}
                                      placeholderText="From date"
                                      dateFormat="yyyy-MM-dd hh:mm"
                                    />
                                    <Gap v={0.5} />
                                  </div>

                                  <div className="col">
                                    <Gap v={1} className="visible-768px" />
                                    <DatePicker
                                      isClearable
                                      autoComplete="off"
                                      id="sel-date-to"
                                      className="form-control"
                                      showTimeSelect={true}
                                      showTwoColumnMonthYearPicker={true}
                                      selected={filterToDate}
                                      onChange={setFilterToDate}
                                      placeholderText="To date"
                                      dateFormat="yyyy-MM-dd hh:mm"
                                    />
                                    <Gap v={0.5} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Gap v={1} />

                    <div className="table-advanced webhook-history-table">
                      <div className="table-wrapper">
                        <table>
                          <thead className="hidden-768px">
                            <tr>
                              <th>Event Type</th>
                              <th className="ps-5">Status</th>
                              <th className="ps-5">URL</th>
                              <th>Tries</th>
                              <th className="ps-5 text-end">Date</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody>
                            <ConditionalElement
                              condition={loading}
                              element={
                                <>
                                  {Array(5)
                                    .fill(1)
                                    .map((value, index) => (
                                      <tr key={`${value}_${index}`}>
                                        <td className="is-placeholder" style={{ width: '80%' }}>
                                          <div className="custom-placeholder hidden-768px"></div>
                                          <div className="visible-768px">
                                            <div className="custom-placeholder" style={{ maxWidth: '200px' }}></div>
                                            <div className="custom-placeholder mt-2" style={{ maxWidth: '80%' }}></div>
                                            <div className="custom-placeholder mt-2" style={{ maxWidth: '150px' }}></div>
                                          </div>
                                        </td>
                                        <td className="ps-5 text-end hidden-768px is-placeholder">
                                          <div className="custom-placeholder"></div>
                                        </td>
                                        <td className="ps-5 text-end hidden-768px is-placeholder">
                                          <div className="custom-placeholder"></div>
                                        </td>
                                        <td className="ps-1 text-end hidden-768px is-placeholder">
                                          <div className="custom-placeholder"></div>
                                        </td>
                                        <td className="ps-5 text-end hidden-768px detail-pointer is-placeholder">
                                          <div className="custom-placeholder"></div>
                                        </td>
                                        <td className="ps-1 text-end is-placeholder" style={{ maxWidth: '30px' }}>
                                          <div className="custom-placeholder d-inline-block" style={{ maxWidth: '30px' }}></div>
                                        </td>
                                      </tr>
                                    ))}
                                </>
                              }
                            />

                            {histories.map(({ id, url, event, status, data, response, trials, createdAt }) => (
                              <tr
                                key={id}
                                onClick={() =>
                                  Modal.Open(
                                    <div className="webhook-event-dialog-info">
                                      <div className="head">
                                        <span
                                          className={`fw-normal badge rounded-pill mb-1 ${
                                            status === 'COMPLETED' ? 'bg-success' : status === 'FAILED' ? 'bg-danger' : 'bg-primary'
                                          }`}
                                        >
                                          {formatStatusType(status)}
                                        </span>
                                        <h4>{formatStatusType(event)} Webhook Event</h4>
                                        <p>
                                          <span className="text-muted">Event date</span>: {moment(createdAt).format('MMM DD, yyyy hh:mm a')}
                                        </p>
                                      </div>

                                      <div className="content">
                                        <div className="content-row">
                                          <div className="content-column fw-bold">Event ID</div>
                                          <div className="content-column">
                                            <CopiableText text={id} />
                                          </div>
                                        </div>

                                        <div className="content-row">
                                          <div className="content-column fw-bold">URL</div>
                                          <div className="content-column">
                                            <span className="text-success fw-bold">POST</span>: <CopiableText text={url} />
                                          </div>
                                        </div>

                                        <div className="content-row">
                                          <div className="content-column fw-bold">Tries</div>
                                          <div className="content-column fw-bold">{trials}</div>
                                        </div>

                                        <div className="content-row">
                                          <div className="content-column fw-bold">Request body</div>
                                          <div className="content-column">
                                            <div className="code-wrap">
                                              <SyntaxHighlighter
                                                language="json"
                                                style={vscDarkPlus}
                                                wrapLines={true}
                                                wrapLongLines={true}
                                                showLineNumbers={true}
                                              >
                                                {JSON.stringify(data, null, 2)}
                                              </SyntaxHighlighter>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="content-row">
                                          <div className="content-column fw-bold">Response body</div>
                                          <div className="content-column">
                                            <div className="code-wrap">
                                              <SyntaxHighlighter
                                                language="json"
                                                style={vscDarkPlus}
                                                wrapLines={true}
                                                wrapLongLines={true}
                                                showLineNumbers={true}
                                              >
                                                {JSON.stringify(response, null, 2)}
                                              </SyntaxHighlighter>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>,
                                    {
                                      customClass: {
                                        popup: 'webhook-event-dialog-popup',
                                        htmlContainer: 'webhook-event-dialog-container',
                                      },
                                    },
                                  )
                                }
                              >
                                <td>
                                  <div>
                                    <div>
                                      {event}{' '}
                                      <span
                                        className={`fw-normal badge rounded-pill ${
                                          status === 'COMPLETED' ? 'bg-success' : status === 'FAILED' ? 'bg-danger' : 'bg-primary'
                                        } visible-768px visible-inline`}
                                      >
                                        {status}
                                      </span>
                                    </div>
                                    <div
                                      className="visible-768px"
                                      style={{ maxWidth: '350px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                    >
                                      <span className="text-success fw-bold">POST</span>: <span className="text-muted">{url}</span>
                                    </div>
                                    <small className="visible-768px visible-inline">
                                      {moment(createdAt).format('MMM DD, yyyy hh:mm a')}
                                    </small>
                                  </div>
                                </td>
                                <td className="ps-5 hidden-768px">
                                  <span
                                    className={`fw-normal badge rounded-pill ${
                                      status === 'COMPLETED' ? 'bg-success' : status === 'FAILED' ? 'bg-danger' : 'bg-primary'
                                    }`}
                                  >
                                    {status}
                                  </span>
                                </td>
                                <td className="ps-5 text-start hidden-768px">
                                  <div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span className="text-success fw-bold">POST</span>: {url}
                                  </div>
                                </td>
                                <td className="hidden-768px">
                                  <div style={{ width: '15px' }}>{trials}</div>
                                </td>
                                <td className="ps-5 hidden-768px text-end hidden-768px">
                                  {moment(createdAt).format('MMM DD, yyyy hh:mm a')}
                                </td>
                                <td className="text-end" onClick={(ev) => ev.stopPropagation()}>
                                  <RcDropdown
                                    trigger={['click']}
                                    overlay={
                                      <Menu>
                                        <MenuItem
                                          className=""
                                          key={`webhook-${id}-resend`}
                                          onClick={() =>
                                            developerApi
                                              .replayWebhookEvent(id)
                                              .then(({ message }) => {
                                                toast.info(message);
                                                fetchWebhookHistory();
                                              })
                                              .catch(handleRequestErrors)
                                          }
                                        >
                                          Resend
                                        </MenuItem>
                                      </Menu>
                                    }
                                    alignPoint
                                  >
                                    <a style={{ display: 'inline-block', width: 30, height: 30, cursor: 'pointer', textAlign: 'center' }}>
                                      <BsThreeDotsVertical />
                                    </a>
                                  </RcDropdown>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <ConditionalElement
                          condition={loading === false && histories.length === 0}
                          element={
                            <div className="no-result">
                              <h5>You have no webhook history to display</h5>
                            </div>
                          }
                        />
                      </div>

                      <Gap v={1} />

                      <div className="table-pagination">
                        <div className="pagination-wrap">
                          <div className="item-total hidden">
                            <span className="color-4F4F4F">Rows per page: &nbsp;&nbsp;</span> 8
                          </div>

                          <ConditionalElement
                            condition={totalItems > size}
                            element={
                              <div className="item-nav">
                                <span className="color-4F4F4F">
                                  {(page - 1) * size + 1} - {pageTotal} of {totalItems.toLocaleString('en')}
                                </span>
                                <Gap h={1} />

                                <a
                                  className={`prev ${loading && loadingPosition === 'LEFT' ? 'loading' : ''}`}
                                  style={{ opacity: !loading && page - 1 <= 0 ? 0.1 : 1 }}
                                  onClick={() => {
                                    if (loading) {
                                      return;
                                    }

                                    const prevPage = page - 1;

                                    if (prevPage <= 0) {
                                      return;
                                    }

                                    setPage(prevPage);
                                    setLoadingPosition('LEFT');
                                  }}
                                />

                                <a
                                  className={`next ${loading && loadingPosition === 'RIGHT' ? 'loading' : ''}`}
                                  style={{ opacity: !loading && page + 1 > Math.ceil(totalItems / size) ? 0.1 : 1 }}
                                  onClick={() => {
                                    if (loading) {
                                      return;
                                    }

                                    const nextPage = page + 1;

                                    if (nextPage > Math.ceil(totalItems / size)) {
                                      return;
                                    }

                                    setPage(nextPage);
                                    setLoadingPosition('RIGHT');
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
