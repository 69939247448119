import React from 'react';
import { RecipientProps } from './common';
import { Button } from '../../components/html/Button';
import { countriesWithBankList } from '../../../constants';
import { baseStore } from '../../../store';
import Gap from '../Gap';
import { ConditionalElement } from '../ConditionalElement';
import { CheckBox } from '../html/Checkbox';
import { WithdrawalAccountDto } from '../../../typings';
import { Modal } from '../../components/Modal';
import { Security2faDialog } from '../../components/2FADialog';
import * as withdrawalApi from '../../../api/withdrawal';
import { handleRequestErrors } from '../../../utils';

export const Stage3 = (props: RecipientProps) => {
  const { lists, rates } = baseStore.getState();
  const { mode, recipient, close = () => {}, goToPrevious = () => {}, updateRecipient = () => {} } = props;

  const processRecipient = (account: Partial<WithdrawalAccountDto>) => {
    if (mode !== 'save-only') {
      return close(account);
    }

    Modal.Open(
      <Security2faDialog
        operation="WITHDRAWAL_ACCOUNT_ADD"
        handleSecurity={async (security, callback) => {
          try {
            await withdrawalApi
              .addAccount({
                ...recipient,
                security,
              })
              .then(close);

            Modal.CloseAll();
          } catch (err) {
            callback(null, err);
            handleRequestErrors(err);
          }
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );
  };

  return (
    <>
      <div className="recipient-account-add">
        <a
          onClick={() => goToPrevious(recipient?.country && countriesWithBankList.includes(recipient.country) ? 'STAGE_1' : 'STAGE_2')}
          className="back-button"
        ></a>

        <div className="head">
          <h4>Confirm {mode === 'save-only' ? 'account' : 'your recipient'}</h4>
          <p>Please inspect {mode === 'save-only' ? 'this account details' : 'your recipient details'} carefully before you proceed.</p>
        </div>

        <div className="recipient-info-preview">
          <div className="info">
            <div className="left">Beneficiary type</div>
            <div className="right">{recipient?.beneficiaryType}</div>
          </div>

          <div className="info">
            <div className="left">Country</div>
            <div className="right">
              {[lists.countries?.find((cnt) => cnt.isoCode === recipient?.country)?.name, recipient?.state, recipient?.city]
                .filter(Boolean)
                .join(', ')}
            </div>
          </div>

          <ConditionalElement
            condition={!!recipient?.address}
            element={
              <div className="info">
                <div className="left">Address</div>
                <div className="right">
                  {recipient?.address}, {recipient?.postalCode}
                </div>
              </div>
            }
          />

          <div className="info">
            <div className="left">Currency</div>
            <div className="right">{rates.currencies.find((curr) => curr.code === recipient?.currency)?.name}</div>
          </div>

          <div className="info">
            <div className="left">Account name</div>
            <div className="right fw-bold">{recipient?.accountName || '-'}</div>
          </div>

          <div className="info">
            <div className="left">Account number</div>
            <div className="right fw-bold">{recipient?.accountNumber}</div>
          </div>

          <ConditionalElement
            condition={!!recipient?.iban}
            element={
              <div className="info">
                <div className="left">IBAN</div>
                <div className="right fw-bold">{recipient?.iban}</div>
              </div>
            }
          />

          <ConditionalElement
            condition={!!recipient?.sortCode}
            element={
              <div className="info">
                <div className="left">Sort code</div>
                <div className="right fw-bold">{recipient?.sortCode}</div>
              </div>
            }
          />

          <div className="info">
            <div className="left">Bank name</div>
            <div className="right fw-bold">{recipient?.bankName}</div>
          </div>

          <ConditionalElement
            condition={!!recipient?.routingNumber}
            element={
              <div className="info">
                <div className="left">Routing number</div>
                <div className="right">{recipient?.routingNumber}</div>
              </div>
            }
          />
          <Gap v={1} />
        </div>

        <ConditionalElement
          condition={mode !== 'save-only'}
          element={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
              <CheckBox
                id="remember-recipient"
                label="Save this recipient?"
                name="remember"
                onChange={(ev) => updateRecipient({ remember: ev.target.checked === true })}
              />
            </div>
          }
        />

        <Gap v={1} />

        <Button
          className="btn btn-primary full-width"
          text={mode === 'save-only' ? 'Save account' : 'Continue'}
          onClick={() => processRecipient(recipient!)}
        />
      </div>
    </>
  );
};
