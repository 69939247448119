import React, { useEffect, useState } from 'react';
import { LocationSelect } from '../LocationSelect';
import { baseStore } from '../../../store';
import { BankDto } from '../../../typings';
import { countriesWithBankList } from '../../../constants';
import * as withdrawalApi from '../../../api/withdrawal';
import { handleRequestErrors } from '../../../utils';
import Gap from '../Gap';
import Select from 'react-select';
import { Input } from '../html/Input';
import { RecipientProps } from './common';
import './styles.scss';
import { ConditionalElement } from '../ConditionalElement';
import { Button } from '../../components/html/Button';

export const Stage1 = (props: RecipientProps) => {
  const { mode, recipient, close = () => {}, updateRecipient = () => {}, goToNext = () => {} } = props;

  const { wallets, rates } = baseStore.getState();
  const [countryCode, setCountryCode] = useState<string | null>();
  const [bankList, setBankList] = useState<BankDto[]>([]);
  const [currency, setCurrency] = useState<{ label: string; value: string } | null>();
  const [currencyOptions, setCurrencyOptions] = useState<any[]>([]);
  const [selectCountries, setSelectCountries] = useState<string[]>([]);
  const [loadingState, setLoadingState] = useState(false);

  const updateCountryDefault = (currencyCode: string) => {
    withdrawalApi
      .getChannels('FIAT')
      .then(({ channels }) => {
        const currencyChannels = channels?.filter((channel) => channel.currency === currencyCode);
        const countries = currencyChannels?.map((channel) => channel.country);
        setSelectCountries(countries);

        if (countries.length === 1) {
          setCountryCode(countries[0]);
          updateRecipient({ country: countries[0] });
        }
      })
      .catch(handleRequestErrors);
  };

  useEffect(() => {
    setCurrency(null);

    setCurrencyOptions(
      wallets!
        .filter(({ currency }) => currency.type === 'FIAT' && currency.activities.includes('WITHDRAW'))
        .map((wallet) => ({ label: wallet.currency.name, value: wallet.currency.code })),
    );

    if (props.currencyCode) {
      setCurrency({ value: props.currencyCode, label: rates.currencies.find((curr) => curr.code === props.currencyCode)?.name! });
      updateRecipient({ currency: props.currencyCode });
    }
  }, [wallets]);

  useEffect(() => {
    if (!currency) {
      return;
    }

    updateCountryDefault(currency.value);
  }, [currency]);

  useEffect(() => {
    if (countryCode && countriesWithBankList.includes(countryCode)) {
      withdrawalApi
        .getBankList(countryCode)
        .then(({ banks }) => setBankList(banks))
        .catch(handleRequestErrors);
    }
  }, [countryCode]);

  return (
    <form
      className="recipient-account-add"
      onSubmit={(ev) => {
        ev.preventDefault();

        if (countryCode && countriesWithBankList.includes(countryCode)) {
          setLoadingState(true);
          // do lookup
          withdrawalApi
            .lookupAccount(recipient?.bankId!, recipient?.accountNumber!)
            .then((response) => {
              updateRecipient({ accountName: response.accountName });
              goToNext(countryCode && countriesWithBankList.includes(countryCode) ? 'STAGE_3' : 'STAGE_2');
            })
            .catch(handleRequestErrors)
            .finally(() => setLoadingState(false));
        } else {
          goToNext(countryCode && countriesWithBankList.includes(countryCode) ? 'STAGE_3' : 'STAGE_2');
        }
      }}
    >
      <a onClick={() => close()} className="back-button"></a>

      <div className="head">
        <h4>Some basic information about your new {mode === 'save-only' ? 'account' : 'recipient'}</h4>
      </div>

      <Gap v={1} />

      <label htmlFor="w-currency">Currency</label>
      <Gap v={0.5} />
      <Select
        required
        id="w-currency"
        name="currency"
        options={currencyOptions}
        onChange={(ev) => {
          setCurrency(ev);
          setCountryCode(null);
          updateRecipient({
            type: undefined,
            bankId: undefined,
            accountNumber: undefined,
            accountName: undefined,
            bankName: undefined,
            address: undefined,
            state: undefined,
            city: undefined,
            postalCode: undefined,
            beneficiaryType: undefined,
            routingNumber: undefined,
          });
          updateRecipient({
            currency: ev?.value,
            country: undefined,
            accountName: undefined,
          });

          updateCountryDefault(ev?.value!);
        }}
        value={currency}
        classNamePrefix="custom-select"
      />
      <Gap v={1} />

      <ConditionalElement
        condition={!!countryCode && countriesWithBankList.includes(countryCode)}
        element={
          <>
            <label htmlFor="w-currency">Beneficiary type</label>
            <Gap v={0.5} />
            <Select
              required
              id="w-beneficiary-type"
              name="beneficiaryType"
              options={[
                { label: 'Individual', value: 'INDIVIDUAL' },
                { label: 'Business', value: 'BUSINESS' },
              ]}
              onChange={(ev) => updateRecipient({ beneficiaryType: ev?.value })}
              classNamePrefix="custom-select"
            />
            <Gap v={1} />
          </>
        }
      />

      <ConditionalElement
        condition={selectCountries.length > 1 || (!!countryCode && !countriesWithBankList.includes(countryCode))}
        element={
          <>
            <LocationSelect
              select={['countries', 'states', 'cities']}
              filterCountries={selectCountries}
              onChange={(ev) => {
                const country = ev.country?.iso;
                setCountryCode(country);

                if (country !== recipient?.country) {
                  updateRecipient({
                    type: undefined,
                    bankId: undefined,
                    accountNumber: undefined,
                    accountName: undefined,
                    bankName: undefined,
                    address: undefined,
                    state: undefined,
                    city: undefined,
                    postalCode: undefined,
                    beneficiaryType: undefined,
                    routingNumber: undefined,
                  });
                }

                updateRecipient({ country, state: ev.state?.name, city: ev.city?.name });
              }}
              gapBottom={1}
            />

            <Input
              required
              label="Address"
              name="address"
              placeholder="Eg. 123, Fast Lane"
              gapbottom={1}
              onChange={(ev) => updateRecipient({ address: ev.target.value })}
            />

            <Input
              required
              label="Postal Code"
              name="postalCode"
              placeholder="Eg. 19905"
              gapbottom={1}
              onChange={(ev) => updateRecipient({ postalCode: ev.target.value })}
            />
          </>
        }
      />

      <ConditionalElement
        condition={!!countryCode && countriesWithBankList.includes(countryCode)}
        element={
          <>
            <label htmlFor="w-currency">Select bank</label>
            <Gap v={0.5} />
            <Select
              required
              options={bankList.map(({ bankId, bankName }) => ({
                label: bankName,
                value: bankId,
              }))}
              name="bankId"
              placeholder="Select bank"
              onChange={(ev) => updateRecipient({ type: 'BANK', bankId: ev?.value, bankName: ev?.label })}
            />
            <Gap v={1} />

            <Input
              required
              label="Account number"
              name="accountNumber"
              placeholder="2004567891"
              gapbottom={1}
              onChange={(ev) => updateRecipient({ accountNumber: ev.target.value })}
              info={recipient?.accountName}
            />
          </>
        }
      />

      <Gap v={1} />

      <Button loading={loadingState} text="Next" className="btn btn-primary full-width" />
    </form>
  );
};
