import React from 'react';
import { useSelector } from 'react-redux';
import { USD, USDT, UseLocalCurrency } from '../../../constants';
import { ReducerStates } from '../../../typings';
import { formatCurrency } from '../../../utils';
import './tickerbar.scss';

export const TickerBar = () => {
  const { kyc, rates } = useSelector((state: ReducerStates) => state);

  return (
    <>
      <div className="ticker-prices-wrap">
        <div className="ticker-inner-wrap" data-vertical_center>
          <ul>
            <li className="title">Price Index:</li>

            {rates.currencies
              .filter((currency) => currency.code !== USDT)
              .map((currency) => {
                const localCurrency = kyc.business?.currency || kyc.individual?.currency;
                const defaultCurrency = UseLocalCurrency ? localCurrency : rates.currencies.find((currencyDef) => currencyDef.code === USD);

                // prettier-ignore
                const conversion = currency.code === USD ? rates.conversions.find((conv) => conv.fromCurrency === USDT && conv.toCurrency === USD) : rates.conversions.find((conv) => conv.fromCurrency === currency.code && conv.toCurrency === (currency.code === localCurrency?.code ? USDT : defaultCurrency?.code));

                const percentageChange = conversion ? conversion.percentageChange : '0';
                const isNegativeChange = conversion?.percentageChange?.toString().startsWith('-');
                const isNoChange = conversion?.percentageChange === 0;

                // prettier-ignore
                const price = conversion ? formatCurrency(defaultCurrency!.type, conversion.rate, currency.type === 'FIAT' ? currency.code : defaultCurrency!.code, currency.type === 'FIAT' ? currency.decimals : defaultCurrency?.decimals) : <span className='custom-placeholder'></span>;

                return (
                  <li key={currency.code}>
                    <div className="set">
                      <div className="name">
                        {currency.name}{' '}
                        <span className={`change ${isNegativeChange ? 'down' : isNoChange ? '' : 'up'}`}>{percentageChange}%</span>
                      </div>
                      <div className="price">{price}</div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};
