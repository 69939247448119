import { CurrencyDefinition, WithdrawalAccountType, WithdrawalMethodType, WithdrawalRequestDto } from '../../../../typings';

export interface WithdrawalProps {
  assetId: string;
  currency: CurrencyDefinition;
  request?: Partial<WithdrawalRequestDto>;
  goToNext?(): void;
  goToPrevious?(): void;
  updateRequest?(data: Partial<WithdrawalRequestDto>): void;
}

export const toMethodType = (type: WithdrawalAccountType): WithdrawalMethodType | null => {
  switch (type) {
    case 'BANK':
      return 'BANK_TRANSFER';
    case 'CRYPTO':
      return 'CRYPTO';
    case 'MOMO':
      return 'MOBILE_MONEY';
    default:
      return null;
  }
};

export const toMethodLabel = (type: WithdrawalMethodType): string | null => {
  switch (type) {
    case 'BANK_TRANSFER':
      return 'Local Bank Transfer';
    case 'BANK_TRANSFER_SEPA':
      return 'Bank Transfer (SEPA)';
    case 'BANK_TRANSFER_ACH':
      return 'Bank Transfer (ACH)';
    case 'BANK_TRANSFER_WIRE':
      return 'Bank Transfer (Wire)';
    case 'BANK_TRANSFER_SWIFT':
      return 'Bank Transfer (SWIFT)';
    case 'BANK_TRANSFER_FASTER_PAYMENTS':
      return 'Bank Transfer (Faster Payments)';
    default:
      return null;
  }
};
