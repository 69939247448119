export const route = {
  authentication: {
    signin: '/signin',
    signup: '/signup',
    signupConfirm: '/signup/confirm/:token',
    requstAccess: '/request-access',
    forgotPassword: '/forgot-password',
    forgotPasswordChange: '/forgot-password/update/:token',
  },
  onboarding: {
    type: '/onboarding/type/:token',
    profile: '/onboarding/profile',
    corporate: '/onboarding/corporate',
    sourceOfFunds: '/onboarding/source-of-funds',
    tradingActivities: '/onboarding-trading-activities',
    termsOfService: '/onboarding/terms-service',
  },
  dashboard: {
    home: '/',
    assets: '/assets',
    notifications: '/notifications',
    orderHistory: '/orders',
    subAddresses: '/addresses',
    settings: '/settings',
    fiatDeposit: '/deposit/fiat/:currency',
    cryptoDeposit: '/deposit/crypto/:currency',
    cards: '/cards',
  },
  p2p: {
    home: '/p2p',
    bot: '/p2p/bot',
    ads: '/p2p/ads',
    transactions: '/p2p/transactions',
    requests: '/p2p/requests',
    disputes: '/p2p/disputes',
  },
  settings: {
    business: '/settings/business',
    security: '/settings/security',
    password: '/settings/security/password',
    bankAccounts: '/settings/bank-accounts',
    cryptoAddresses: '/settings/crypto-addresses',
    team: '/settings/team',
    compliance: '/settings/compliance',
    developers: '/settings/developers',
    developerKeys: '/settings/developers/keys',
    developerWebhooks: '/settings/developers/webhooks',
    developerWebhookHistory: '/settings/developers/webhooks-history',
    developerTools: '/settings/developers/tools',
    notifications: '/settings/notifications',
    system: '/settings/system',
  },
  operation: '/operation/:type/:token',
};
