import config from '../config';
import Request from '../helpers/request';
import {
  BanksListDto,
  PublicWithdrawalChannelsDto,
  StatusDto,
  WalletAssetTransaction,
  WithdrawalAccountDto,
  WithdrawalAccountListDto,
  WithdrawalAccountLookupResponse,
  WithdrawalAccountType,
  WithdrawalMethodsDto,
  WithdrawalRequestDto,
  WithSecurity,
} from '../typings';
import { getQueryParams } from '../utils';

export const getMethods = async (sourceCurrency: string, targetCurrency: string, countryCode: string): Promise<WithdrawalMethodsDto> => {
  return await Request.getWithUserToken<WithdrawalMethodsDto>(
    `${config.api.withdrawal}/methods?sourceCurrency=${sourceCurrency}&targetCurrency=${targetCurrency}&countryCode=${countryCode}`,
  );
};

export const getChannels = async (type: 'FIAT' | 'CRYPTO'): Promise<PublicWithdrawalChannelsDto> => {
  return await Request.getWithUserToken<PublicWithdrawalChannelsDto>(`${config.api.withdrawal}/channels?type=${type}`);
};

export const getAccounts = async (type?: WithdrawalAccountType): Promise<WithdrawalAccountListDto> => {
  return await Request.getWithUserToken<WithdrawalAccountListDto>(`${config.api.withdrawal}/me/accounts${getQueryParams({ type })}`);
};

export const addAccount = async (data: WithSecurity<Partial<WithdrawalAccountDto>>): Promise<WithdrawalAccountDto> => {
  return await Request.putWithUserToken<WithdrawalAccountDto>(`${config.api.withdrawal}/me/accounts`, {
    data,
  });
};

export const removeAccount = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${config.api.withdrawal}/me/accounts/${id}`, {
    data: {
      security,
    },
  });
};

export const lookupAccount = async (bankId: string, accountNumber: string): Promise<WithdrawalAccountLookupResponse> => {
  return await Request.postWithUserToken<WithdrawalAccountLookupResponse>(`${config.api.withdrawal}/me/accounts/lookup`, {
    data: {
      bankId,
      accountNumber,
    },
  });
};

export const createTransaction = async (data: WithSecurity<WithdrawalRequestDto>): Promise<WalletAssetTransaction> => {
  return await Request.postWithUserToken<WalletAssetTransaction>(`${config.api.withdrawal}/me/transactions`, {
    data,
  });
};

export const getBankList = async (countryCode: string): Promise<BanksListDto> => {
  return await Request.getWithUserToken<BanksListDto>(`${config.api.withdrawal}/banks/${countryCode}/list`);
};
