export const LOCAL_APP_TOKEN = 'app_token';
export const FCM_SUB_ID = 'fcm_sub_id';

export const UseLocalCurrency = false;
export const USD = 'USD';
export const USDT = 'USDT';
export const BTC = 'BTC';

export const StableCoins = [USDT, 'BUSD', 'USDC'];

export const noWithdrawalDecimals = ['NGN'];

export const RATE_REFRESH_INTERVAL = 300000;

export const P2P_DATA_REFRESH_INTERVAL = 60000;

export const BusinessTypeOptions = [
  {
    label: 'Corporate Account',
    value: 'CORPORATE',
  },
  {
    label: 'Trust Account',
    value: 'TRUST',
  },
  {
    label: 'Self Managed Super Fund',
    value: 'SMSF',
  },
];

export const AccountTypeOptions = [
  {
    label: 'HNI Account',
    value: 'INDIVIDUAL',
  },
  ...BusinessTypeOptions,
];

export const meansOfIdDocsOptions = [
  {
    label: 'International Passport',
    value: 'INTL_PASSPORT',
  },
  {
    // eslint-disable-next-line quotes
    label: "International Driver's license",
    value: 'INTL_DRIVERS_LICENSE',
  },
  {
    label: 'Selfie',
    value: 'SELFIE',
  },
];

export const proofOfAddressDocsOptions = [
  {
    label: 'Utility Bill',
    value: 'UTILITY_BILL',
  },
  {
    label: 'Bank Statement',
    value: 'BANK_STATEMENT',
  },
];

export const businessKycDocsOptions = [
  {
    label: 'Certificate of Registration',
    value: 'CERTIFICATE_OF_REGISTRATION',
  },
  {
    label: 'Certificate of Incorporation',
    value: 'CERTIFICATE_OF_INCORPORATION',
  },
  {
    label: 'Certification of Trust Deed',
    value: 'CERTIFICATE_OF_TRUST_DEED',
  },
  {
    label: 'Register of Directors',
    value: 'REGISTER_OF_DIRECTORS',
  },
  {
    label: 'Register of Shareholders',
    value: 'REGISTER_OF_SHAREHOLDERS',
  },
  {
    label: 'Register of Authorised Signatories',
    value: 'REGISTER_OF_SIGNATORIES',
  },
  {
    label: 'Proof of Address',
    value: 'PROOF_OF_BUSINESS_ADDRESS',
    options: [
      {
        label: 'Utility Bill',
        value: 'UTILITY_BILL',
      },
      {
        label: 'Bank Statement',
        value: 'BANK_STATEMENT',
      },
    ],
  },
];

export const displayCurrencies = ['USD', 'USDC', 'BTC'];

export const countriesWithBankList = ['NG'];

export const supportedCountryCurrencies = {
  NG: ['NGN'],
  US: ['USD'],
};
