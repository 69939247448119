import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { toMethodLabel, toMethodType, WithdrawalProps } from './common';
import { Button } from '../../html/Button';
import Gap from '../../Gap';
import { cleanObj, formatCurrency, handleRequestErrors } from '../../../../utils';
import { baseStore } from '../../../../store';
import { CurrencyDefinition } from '../../../../typings';
import * as withdrawalApi from '../../../../api/withdrawal';
import * as walletApi from '../../../../api/wallets';
import * as walletActions from '../../../../actions/wallets';
import { Modal } from '../../Modal';
import { Security2faDialog } from '../../2FADialog';
import { ConditionalElement } from '../../ConditionalElement';

export const Stage3 = (props: WithdrawalProps) => {
  const { request, goToPrevious = () => {} } = props;
  const { rates } = baseStore.getState();
  const [currency, setCurrency] = useState<CurrencyDefinition>();

  useEffect(() => {
    setCurrency(rates.currencies.find(({ code }) => code === request?.currency));
  }, [request?.currency]);

  const executeWithdrawal = () => {
    Modal.Open(
      <Security2faDialog
        operation="ASSET_WITHDRAWAL"
        handleSecurity={async (security, callback) => {
          withdrawalApi
            .createTransaction({
              assetId: request?.assetId!,
              methodId: request?.methodId || 'BANK_TRANSFER',
              amount: request?.amount!,
              currency: request?.currency!,
              recipient: cleanObj(request?.recipient),
              memo: request?.memo!,
              security,
            })
            .then(() => {
              Modal.Alert(
                'Withdrawal Success',
                `You've successfully withdrawn ${formatCurrency(
                  currency?.type!,
                  request?.amount!,
                  request?.currency!,
                  currency?.decimals,
                )}`,
              );
              setTimeout(() => walletApi.getWallets().then((res) => baseStore.dispatch(walletActions.setWalletAssets(res.assets))), 1500);
            })
            .catch((err) => {
              callback(null, err);
              handleRequestErrors(err);
            });
        }}
      />,
      {
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: 'modal-mobile-fullscreen',
          popup: 'max-w-350x',
        },
      },
    );
  };

  return (
    <>
      <a onClick={() => goToPrevious()} className="back-button"></a>

      <div className="head">
        <h4>Confirm your transfer</h4>
        <p>Please inspect your recipient details carefully before you proceed.</p>
      </div>

      {request ? (
        <div className="content">
          <div className="withdrawal-info-preview">
            <div className="info">
              <div className="left">Amount</div>
              <div className="right fw-bold">{formatCurrency(currency?.type!, request.amount!, request.currency!, currency?.decimals)}</div>
            </div>

            <div className="info">
              <div className="left">Fee</div>
              <div className="right">{formatCurrency(currency?.type!, request.feeAmount || 0, request.currency!, currency?.decimals)}</div>
            </div>

            <div className="info">
              <div className="left">Recipient</div>
              <div className="right">
                <div className="fw-bold">{request.recipient?.accountNumber}</div>
                <div className="fw-bold">{request.recipient?.accountName}</div>
                <div> {request.recipient?.bankName}</div>
              </div>
            </div>

            <ConditionalElement
              condition={!!request.recipient?.iban}
              element={
                <div className="info">
                  <div className="left">IBAN</div>
                  <div className="right fw-bold">{request.recipient?.iban}</div>
                </div>
              }
            />

            <ConditionalElement
              condition={!!request.recipient?.sortCode}
              element={
                <div className="info">
                  <div className="left">Sort code</div>
                  <div className="right fw-bold">{request.recipient?.sortCode}</div>
                </div>
              }
            />

            <ConditionalElement
              condition={!!request.recipient?.routingNumber}
              element={
                <div className="info">
                  <div className="left">Routing number</div>
                  <div className="right fw-bold">{request.recipient?.routingNumber}</div>
                </div>
              }
            />

            <div className="info">
              <div className="left">Method</div>
              <div className="right">
                {toMethodLabel(request.methodId!) ||
                  toMethodType(request.recipient?.type!)
                    ?.split('_')
                    .map((str, i) => (i === 0 ? _.capitalize(str.toLocaleLowerCase()) : _.capitalize(str.toLocaleLowerCase())))
                    .join(' ')}
              </div>
            </div>

            {request.memo ? (
              <div className="info">
                <div className="left">Memo</div>
                <div className="right">{request.memo}</div>
              </div>
            ) : null}
          </div>

          <Gap v={1} />

          <Button className="btn btn-primary full-width" text="Continue" onClick={() => executeWithdrawal()} />
        </div>
      ) : null}
    </>
  );
};
