import { Helmet } from 'react-helmet';
import React from 'react';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import Gap from '../../components/Gap';
import '../settings.scss';
import './styles.scss';
import { route } from '../../../routes';
import RcDropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';

export const DeveloperTools = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet title="Dev Tools | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate(route.settings.developers)}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Developer Tools</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="devtools-wrap">
                      <div className="devtools-nav">
                        <ul>
                          <li>
                            <RcDropdown
                              trigger={['click']}
                              overlay={
                                <Menu>
                                  <MenuItem key="devtools-fiat-deposit-reprocess">Reprocess deposit</MenuItem>
                                  <MenuItem key="devtools-fiat-deposit-webhook">Resend webhook</MenuItem>
                                </Menu>
                              }
                              alignPoint
                            >
                              <a className="fw-bold">Fiat Collections</a>
                            </RcDropdown>
                          </li>
                          <li>
                            <RcDropdown
                              trigger={['click']}
                              overlay={
                                <Menu>
                                  <MenuItem key="devtools-fiat-deposit-reprocess">Reprocess crypto transaction</MenuItem>
                                </Menu>
                              }
                              alignPoint
                            >
                              <a className="fw-bold">Crypto Collections</a>
                            </RcDropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
