import React, { useState } from 'react';
import { Stage1 } from './Stage1';
import { RecipientProps, Stages } from './common';
import './styles.scss';
import { Stage2 } from './Stage2';
import { Stage3 } from './Stage3';

export const AddNewFiatRecipient = (props: RecipientProps) => {
  const { close = () => {} } = props;

  const [stage, toStage] = useState<Stages>('STAGE_1');
  const [recipientData, updateRecipientData] = useState<Partial<{}>>({});

  return (
    <>
      <div style={{ display: stage === 'STAGE_1' ? 'block' : 'none' }}>
        <Stage1
          {...props}
          recipient={recipientData}
          close={close}
          goToNext={(stage) => toStage(stage || 'STAGE_2')}
          updateRecipient={(data) => updateRecipientData({ ...recipientData, ...data })}
        />
      </div>

      <div style={{ display: stage === 'STAGE_2' ? 'block' : 'none' }}>
        <Stage2
          {...props}
          recipient={recipientData}
          goToPrevious={(stage) => toStage(stage || 'STAGE_1')}
          goToNext={(stage) => toStage(stage || 'STAGE_3')}
          updateRecipient={(data) => updateRecipientData({ ...recipientData, ...data })}
        />
      </div>

      <div style={{ display: stage === 'STAGE_3' ? 'block' : 'none' }}>
        <Stage3
          {...props}
          recipient={recipientData}
          goToPrevious={(stage) => toStage(stage || 'STAGE_2')}
          updateRecipient={(data) => updateRecipientData({ ...recipientData, ...data })}
        />
      </div>
    </>
  );
};
