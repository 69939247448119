import React, { useEffect, useState } from 'react';
import { WithdrawalProps } from './common';
import { Button } from '../../html/Button';
import Gap from '../../Gap';
import { WithdrawalAccountDto } from '../../../../typings';
import * as withdrawalApi from '../../../../api/withdrawal';
import * as withdrawalActions from '../../../../actions/withdrawal';
import { baseStore } from '../../../../store';
import { handleRequestErrors } from '../../../../utils';
import SafeIcon from '../../../../assets/no-txn.png';
import { AddNewFiatRecipient } from '../../AddNewFiatRecipient';

export const Stage2 = (props: WithdrawalProps) => {
  const { request, updateRequest = () => {}, goToNext = () => {}, goToPrevious = () => {} } = props;
  const [accounts, setAccounts] = useState<WithdrawalAccountDto[]>([]);
  const [loadingState, setLoadingState] = useState(false);
  const [stage, updateStage] = useState<'RECIPIENT_SELECT' | 'RECIPIENT_ADD'>('RECIPIENT_SELECT');

  useEffect(() => {
    setLoadingState(true);

    if (!request?.currency) {
      return;
    }

    withdrawalApi
      .getAccounts('BANK')
      .then((data) => {
        setAccounts(data.accounts.filter((account) => account.currency === request?.currency));
        baseStore.dispatch(withdrawalActions.setAccounts(data.accounts));
      })
      .catch(handleRequestErrors)
      .finally(() => setLoadingState(false));
  }, [request?.methodId, request?.currency]);

  return (
    <>
      <div className="content">
        {stage === 'RECIPIENT_SELECT' ? (
          <>
            <a onClick={() => goToPrevious()} className="back-button"></a>

            <div className="head">
              <h4>Who is your recipient?</h4>
            </div>

            <Gap v={1} />

            <div className="recipient-list-wrap">
              <ul className="recipient-select-list">
                {loadingState ? (
                  <div className="loading-state">Loading...</div>
                ) : accounts.length === 0 ? (
                  <div className="empty-state">
                    <img src={SafeIcon} alt="safe" />
                    <h6>You have no saved recipients</h6>
                  </div>
                ) : (
                  accounts.map((acct) => (
                    <li
                      key={acct.id}
                      onClick={() => {
                        updateRequest({ recipient: acct });
                        goToNext();
                      }}
                    >
                      <span className={`initials img-${acct.beneficiaryType?.toLowerCase() || 'individual'}`}></span>
                      <div className="info">
                        <div className="title">
                          <h5>{acct.accountName}</h5>
                        </div>
                        <div className="others">
                          <span className="account-number">{acct.accountNumber}</span> &middot;&nbsp;
                          <span className="bank-name">{acct.bankName}</span>
                        </div>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>

            <Gap v={1} />

            <Button
              type="submit"
              className="btn btn-primary full-width"
              text="Send to new recipient"
              onClick={() => updateStage('RECIPIENT_ADD')}
            />
          </>
        ) : (
          <AddNewFiatRecipient
            currencyCode={request?.currency || request?.recipient?.currency}
            close={(recipient) => {
              if (recipient && recipient.currency === request?.currency) {
                updateRequest({ recipient: recipient as any });
                goToNext();
              } else {
                updateStage('RECIPIENT_SELECT');
              }
            }}
          ></AddNewFiatRecipient>
        )}
      </div>
    </>
  );
};
