import Request from '../helpers/request';
import { Paging, StatusDto, WithSecurity } from '../typings';
import {
  DeveloperKeyDto,
  DeveloperKeysDto,
  WebhookHistoryDto,
  WebhookSubscriptionDto,
  WebhookSubscriptionsDto,
} from '../typings/developer';
import { cleanObj, getQueryParams } from '../utils';

export const getKeys = async (): Promise<DeveloperKeysDto> => {
  return await Request.getWithUserToken<DeveloperKeysDto>(`${process.env.REACT_APP_API_URL}/developer/me/keys`);
};

export const createKey = async (data: WithSecurity<Partial<DeveloperKeyDto>>): Promise<DeveloperKeyDto> => {
  const { label, whiteListedIPs, permissions, security } = data;

  return await Request.postWithUserToken<DeveloperKeyDto>(`${process.env.REACT_APP_API_URL}/developer/me/keys`, {
    data: {
      label,
      whiteListedIPs,
      permissions,
      security,
    },
  });
};

export const removeKey = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/developer/me/keys/${id}`, {
    data: {
      security,
    },
  });
};

export const getWebhookSubscriptions = async (): Promise<WebhookSubscriptionsDto> => {
  return await Request.getWithUserToken<WebhookSubscriptionsDto>(`${process.env.REACT_APP_API_URL}/developer/me/webhook/subscriptions`);
};

export const getWebhookHistories = async (
  page = 1,
  size = 25,
  status?: string,
  event?: string,
  url?: string,
  fromDate?: string,
  toDate?: string,
): Promise<Paging<WebhookHistoryDto[]>> => {
  return await Request.getWithUserToken<Paging<WebhookHistoryDto[]>>(
    // prettier-ignore
    `${process.env.REACT_APP_API_URL}/developer/me/webhook/histories${getQueryParams(cleanObj({ page, size, status, event, url, fromDate, toDate }))}`,
  );
};

export const createWebhookSubscription = async (data: WithSecurity<Partial<WebhookSubscriptionDto>>): Promise<WebhookSubscriptionDto> => {
  const { url, events, security, secret } = data;

  return await Request.putWithUserToken<WebhookSubscriptionDto>(`${process.env.REACT_APP_API_URL}/developer/me/webhook/subscriptions`, {
    data: {
      url,
      events,
      secret,
      security,
    },
  });
};

export const updateWebhookSubscription = async (
  subscriptionId: string,
  data: WithSecurity<Partial<WebhookSubscriptionDto>>,
): Promise<WebhookSubscriptionDto> => {
  const { events, secret, security } = data;

  return await Request.postWithUserToken<WebhookSubscriptionDto>(
    `${process.env.REACT_APP_API_URL}/developer/me/webhook/subscriptions/${subscriptionId}`,
    {
      data: {
        events,
        secret,
        security,
      },
    },
  );
};

export const removeWebhookSubscription = async (id: string, security: string): Promise<StatusDto> => {
  return await Request.deleteWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/developer/me/webhook/subscriptions/${id}`, {
    data: {
      security,
    },
  });
};

export const replayWebhookEvent = async (id: string): Promise<StatusDto> => {
  return await Request.postWithUserToken<StatusDto>(`${process.env.REACT_APP_API_URL}/developer/me/webhook/${id}/replay`);
};
