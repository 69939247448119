import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/DashboardHeader';
import { Sidebar } from '../../components/DashboardSidebar';
import Gap from '../../components/Gap';
import { SettingsSidebar } from '../../components/SettingsSidebar';
import { useNavigate } from 'react-router-dom';
import * as developerApi from '../../../api/developer';
import * as developerAction from '../../../actions/developer';
import { useDispatch } from 'react-redux';
import { handleRequestErrors } from '../../../utils';
import '../settings.scss';
import './styles.scss';
import { Link } from 'react-router-dom';
import { route } from '../../../routes';

export const DeveloperSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    developerApi
      .getKeys()
      .then((data) => dispatch(developerAction.setKeys(data.keys)))
      .catch((err) => handleRequestErrors(err));
  }, []);

  return (
    <>
      <Helmet title="Developers | Host Capital" />

      <div className="dashboard-wrapper">
        <div className="dashboard-page">
          <Sidebar />

          <div className="dashboard-content">
            <div className="dashboard-content-wrap">
              <div className="content-inner">
                <Header withTicker={false} />

                <div className="settings-page-wrap">
                  <SettingsSidebar />

                  <div className="settings-page-content">
                    <div className="row">
                      <div className="col-auto hidden-768px" data-vertical_center>
                        <a className="back-nav cursor-pointer" onClick={() => navigate('../')}>
                          Back
                        </a>
                      </div>

                      <div className="col hidden-768px" data-vertical_center>
                        <h6 className="p-0 m-0">Developers</h6>
                      </div>
                    </div>

                    <Gap v={2} className="hidden-768px" />

                    <div className="page-content-inner">
                      <ul className="security-settings-list">
                        <li>
                          <div className="wrap">
                            <h6>Developer Keys</h6>
                            <Gap v={1} />

                            <div>Create developer API keys, update key permissions and restrictions.</div>

                            <Gap v={1} />

                            <Link to={route.settings.developerKeys} className="btn btn-primary btn-default">
                              Manage Keys
                            </Link>
                          </div>
                        </li>

                        <li>
                          <div className="wrap">
                            <h6>Webhook Subscriptions</h6>
                            <Gap v={1} />

                            <div>Subscribe to webhooks. Receive notifications for Deposits, Withdrawals and so on.</div>

                            <Gap v={1} />

                            <Link to={route.settings.developerWebhooks} className="btn btn-primary btn-default">
                              Manage Subscriptions
                            </Link>
                          </div>
                        </li>

                        <li>
                          <div className="wrap">
                            <h6>Webhook History</h6>
                            <Gap v={1} />

                            <div>All of your webhook histories.</div>

                            <Gap v={1} />

                            <Link to={route.settings.developerWebhookHistory} className="btn btn-primary btn-default">
                              View History
                            </Link>
                          </div>
                        </li>

                        <li className="hidden">
                          <div className="wrap">
                            <h6>Tools</h6>
                            <Gap v={1} />

                            <div>Custom resolution tools.</div>

                            <Gap v={1} />

                            <Link to={route.settings.developerTools} className="btn btn-primary btn-default">
                              Tools
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
