import { DeveloperState, DeveloperAction } from '../typings';

const initialState: DeveloperState = {
  keys: [],
  subscriptions: [],
};

export default (state = initialState, action: DeveloperAction): DeveloperState => {
  switch (action.type) {
    case 'ADD_KEY':
      return {
        ...state,
        keys: [action.key!, ...state.keys],
      };
    case 'REMOVE_KEY':
      return {
        ...state,
        keys: state.keys.filter((key) => key.id !== action.id),
      };
    case 'SET_KEYS':
      const { keys = [] } = action;

      return {
        ...state,
        keys,
      };
    case 'ADD_WEBHOOK_SUBSCRIPTION':
      return {
        ...state,
        subscriptions: [action.subscription!, ...state.subscriptions],
      };
    case 'UPDATE_WEBHOOK_SUBSCRIPTION':
      return {
        ...state,
        subscriptions: state.subscriptions.map((sub) => {
          if (action.subscription?.id === sub.id) {
            return {
              ...sub,
              ...action.subscription,
            };
          }

          return sub;
        }),
      };
    case 'REMOVE_WEBHOOK_SUBSCRIPTION':
      return {
        ...state,
        subscriptions: state.subscriptions.filter((sub) => sub.id !== action.id),
      };
    case 'SET_WEBHOOK_SUBSCRIPTIONS':
      const { subscriptions = [] } = action;

      return {
        ...state,
        subscriptions,
      };

    default:
      return state;
  }
};
