import React, { useEffect, useState } from 'react';
import i18n from './i18n';
import config from './config';
import * as fcm from 'firebase/messaging';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { FCM_SUB_ID, LOCAL_APP_TOKEN, RATE_REFRESH_INTERVAL, USDT } from './constants';
import { route } from './routes';
import { Signin } from './modules/Signin';
import { Signup, SignupEmailConfirm } from './modules/Signup';
import { Assets } from './modules/Assets';
import { OrderHistory } from './modules/OrderHistory';
import { ForgotPassword, ForgotPasswordChange } from './modules/ForgotPassword';
import { ProfileSettings } from './modules/Settings/Profile';
import { ErrorPage404 } from './modules/ErrorPages/ErrorPage404';
import { OnboardingAccountType } from './modules/Onboarding/OnboardingAccountType';
import { OnboardingProfile } from './modules/Onboarding/OnboardingProfile';
import { OnboardingCorporate } from './modules/Onboarding/OnboardingCorporate';
import { OnboardingFundsSource } from './modules/Onboarding/OnboardingFundsSource';
import { OnboardingTradingActs } from './modules/Onboarding/OnboardingTradingActs';
import { OnboardingTermsOfService } from './modules/Onboarding/OnboardingTermsOfService';
import { CryptoAddressSettings } from './modules/Settings/CyrptoAddresses';
import { BankAccountSettings } from './modules/Settings/BankAccounts';
import { PasswordSettings } from './modules/Settings/Password';
import { SecuritySettings } from './modules/Settings/Security';
import { TeamSettings } from './modules/Settings/Team';
import { ComplianceSettings } from './modules/Settings/Compliance';
import { DeveloperSettings } from './modules/Settings/Developers';
import { NotificationSettings } from './modules/Settings/Notifications';
import { SystemSettings } from './modules/Settings/System';
import { BusinessSettings } from './modules/Settings/Business';
import { useDispatch } from 'react-redux';
import { loadStateWithExpiry, saveState } from './localstorage';
import { handleRequestErrors } from './utils';
import { setUser } from './actions/user';
import { setWalletAssets } from './actions/wallets';
import { setCountries } from './actions/lists';
import * as listsApi from './api/lists';
import * as userApi from './api/user';
import * as walletsApi from './api/wallets';
import * as kycApi from './api/kyc';
import * as ratesApi from './api/rates';
import * as notificationsApi from './api/notifications';
import { setBusinessKycInfo, setIndividualKycInfo } from './actions/kyc';
import { addConversion, setCurrencies } from './actions/rates';
import { RequestAccess } from './modules/RequestAccess';
import { ExchangeRate, ReducerStates } from './typings';
import { useSelector } from 'react-redux';
import { OperationsHandler } from './modules/components/OperationsHandler';
import { updateMiscSettings } from './actions/misc';
import { messaging } from './integrations/fcm';
import { registerFirebaseSw } from './serviceWorders';
import 'react-toastify/dist/ReactToastify.min.css';
import { setBusinessInfo } from './actions/business';
import { Addresses } from './modules/Addresses';
import { DeveloperKeys } from './modules/Settings/Developers/Keys';
import { DeveloperWebhooks } from './modules/Settings/Developers/Webhooks';
import { Cards } from './modules/Cards';
import { P2pProfile } from './modules/P2P/Profile';
import { P2pBot } from './modules/P2P/Bot';
import { P2pRequests } from './modules/P2P/Requests';
import { P2pTransactions } from './modules/P2P/Transactions';
import { P2pDisputes } from './modules/P2P/Disputes';
import { P2pAds } from './modules/P2P/Ads';
import { DeveloperWebhookHistory } from './modules/Settings/Developers/WebhookHistory';
import { DeveloperTools } from './modules/Settings/Developers/DevTools';

i18n.configure();

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const location = useLocation();

  const getToken = () => loadStateWithExpiry(LOCAL_APP_TOKEN);

  if (!getToken()) {
    return <Navigate to={route.authentication.signin} replace state={{ from: location }} />;
  }

  const initFCM = async () => {
    const ready = (await fcm.isSupported()) && messaging;

    if (ready) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fcm.onMessage(messaging, (payload) => {
        if (!getToken()) {
          return;
        }

        const notification = JSON.parse(payload.data?.notification || '{}');

        toast(
          <>
            <b>{notification.title}</b>
            <p>{notification.body}</p>
          </>,
        );
      });

      Notification.requestPermission()
        .then(async (permission) => {
          if (permission === 'granted') {
            const serviceWorker = await registerFirebaseSw();

            fcm
              .getToken(messaging, {
                vapidKey: config.fcm.publicKey,
                serviceWorkerRegistration: serviceWorker,
              })
              .then(async (token) => {
                try {
                  const subscription = await notificationsApi.addSubscription({
                    platform: 'GCM_WEB',
                    device: `${browserName} ${fullBrowserVersion}`,
                    token,
                  });

                  saveState(FCM_SUB_ID, subscription.id);
                } catch (err) {
                  // ignorable
                }
              });
          }
        })
        .catch((err: any) => {
          console.log('Unable to get permission for notifications: ', err);
        });
    }
  };

  useEffect(() => {
    initFCM();
  }, []);

  return <>{props.children}</>;
};

export const App = () => {
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  // const [p2pDataTs, setP2pDataTs] = useState<NodeJS.Timer>();
  const [rateConvTs, setRateConvTs] = useState<NodeJS.Timer>();
  const { user, kyc, misc } = useSelector((state: ReducerStates) => state);
  const localCurrency = kyc.business?.currency || kyc.individual?.currency;

  window.addEventListener('online', () => setIsOnline(true));
  window.addEventListener('offline', () => setIsOnline(false));

  useEffect(() => {
    if (!misc.token || misc.gottenActiveUser) {
      return;
    }

    userApi
      .getActiveUser()
      .then((user) => {
        dispatch(setUser(user));
        dispatch(updateMiscSettings({ gottenActiveUser: true }));

        kycApi.getPersonalInfo().then((response) => dispatch(setIndividualKycInfo(response)));

        if (user.type !== 'INDIVIDUAL') {
          userApi.getActiveBusiness().then((response) => dispatch(setBusinessInfo(response)));
          kycApi.getBusinessInfo().then((response) => dispatch(setBusinessKycInfo(response)));
        }
      })
      .catch((err) => handleRequestErrors(err, 'Some error occurred. We could not get your details.'));
  }, [misc.token]);

  useEffect(() => {
    if (!misc.token || (kyc.business || kyc.individual)?.status !== 'VERIFIED') {
      return;
    }

    walletsApi
      .getWallets()
      .then((response) => dispatch(setWalletAssets(response.assets)))
      .catch((err) => handleRequestErrors(err, 'Unable to get your wallet assets'));
  }, [misc.token, kyc.business, kyc.individual]);

  useEffect(() => {
    if (!misc.token || misc.gottenLocations) {
      return;
    }

    listsApi
      .getCountries()
      .then((response) => {
        dispatch(setCountries(response.countries));
        dispatch(updateMiscSettings({ gottenLocations: true }));
      })
      .catch(() => void 0);
  }, [misc.token]);

  useEffect(() => {
    if (!misc.token || misc.gottenCurrencies) {
      return;
    }

    ratesApi
      .getCurrencies()
      .then((response) => {
        dispatch(setCurrencies(response.currencies));
        dispatch(updateMiscSettings({ gottenCurrencies: true }));
      })
      .catch(() => void 0);
  }, [misc.token]);

  const loadConversions = async () => {
    if (isWorking || !navigator.onLine || !isOnline) {
      return;
    }

    setIsWorking(true);

    Promise.all([
      ratesApi.getConversions({ toCurrency: USDT }),
      ratesApi.getConversions({ fromCurrency: USDT }),
      ratesApi.getConversions({ toCurrency: user.preferredCurrency || localCurrency?.code }),
      ratesApi.getConversions({ fromCurrency: user.preferredCurrency || localCurrency?.code }),
    ])
      .then((response) => {
        const rates = response.reduce((accum, response) => accum.concat(response.rates), [] as ExchangeRate[]);
        rates.forEach((rate) => dispatch(addConversion(rate)));
        dispatch(updateMiscSettings({ gottenConversions: true }));
      })
      .catch(() => void 0)
      .finally(() => setIsWorking(false));
  };

  useEffect(() => {
    if (!misc.token || misc.gottenConversions || !(kyc.individual || kyc.business)) {
      return;
    }

    const localCurrency = kyc.business?.currency?.code || kyc.individual?.currency?.code;

    if (!localCurrency) {
      return;
    }

    loadConversions();

    if (!rateConvTs) {
      setRateConvTs(
        setInterval(() => {
          loadConversions();
        }, RATE_REFRESH_INTERVAL),
      );
    }
  }, [misc.token, kyc]);

  // const loadP2pData = async () => {
  //   if (!navigator.onLine || !isOnline) {
  //     return;
  //   }

  //   Promise.all([P2pApi.getProfile(), P2pApi.getRequests('PENDING'), P2pApi.getTransactions('ONGOING'), P2pApi.getDisputes('IN_PROGRESS')])
  //     .then(([profile, requests, transactions, disputes]) => {
  //       dispatch(p2pActions.setProfile(profile));
  //       dispatch(p2pActions.setRequests(requests.records));
  //       dispatch(p2pActions.setTransactions(transactions.records));
  //       dispatch(p2pActions.setDisputes(disputes.records));
  //     })
  //     .catch(() => void 0);
  // };

  // useEffect(() => {
  //   if (!misc.token || misc.gottenP2pData) {
  //     return;
  //   }

  //   loadP2pData();

  //   if (!p2pDataTs) {
  //     setP2pDataTs(
  //       setInterval(() => {
  //         loadP2pData();
  //       }, P2P_DATA_REFRESH_INTERVAL),
  //     );
  //   }
  // }, [misc.token]);

  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar={true} closeButton={<>✕</>} position="top-center" />

      <Routes>
        <Route path={route.authentication.signin} element={<Signin />} />
        <Route path={route.authentication.signup} element={<Signup />} />
        <Route path={route.authentication.signupConfirm} element={<SignupEmailConfirm />} />
        <Route path={route.authentication.requstAccess} element={<RequestAccess />} />
        <Route path={route.authentication.forgotPassword} element={<ForgotPassword />} />
        <Route path={route.authentication.forgotPasswordChange} element={<ForgotPasswordChange />} />
        <Route path={route.onboarding.type} element={<OnboardingAccountType />} />
        <Route path={route.operation} element={<OperationsHandler />} />

        <Route
          path={route.onboarding.profile}
          element={
            <ProtectedRoute>
              <OnboardingProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.onboarding.corporate}
          element={
            <ProtectedRoute>
              <OnboardingCorporate />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.onboarding.sourceOfFunds}
          element={
            <ProtectedRoute>
              <OnboardingFundsSource />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.onboarding.tradingActivities}
          element={
            <ProtectedRoute>
              <OnboardingTradingActs />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.onboarding.termsOfService}
          element={
            <ProtectedRoute>
              <OnboardingTermsOfService />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.dashboard.home}
          element={
            <ProtectedRoute>
              <Assets />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.dashboard.cards}
          element={
            <ProtectedRoute>
              <Cards />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.p2p.home}
          element={
            <ProtectedRoute>
              <P2pProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.p2p.bot}
          element={
            <ProtectedRoute>
              <P2pBot />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.p2p.ads}
          element={
            <ProtectedRoute>
              <P2pAds />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.p2p.requests}
          element={
            <ProtectedRoute>
              <P2pRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.p2p.transactions}
          element={
            <ProtectedRoute>
              <P2pTransactions />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.p2p.disputes}
          element={
            <ProtectedRoute>
              <P2pDisputes />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.dashboard.subAddresses}
          element={
            <ProtectedRoute>
              <Addresses />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.dashboard.orderHistory}
          element={
            <ProtectedRoute>
              <OrderHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.dashboard.settings}
          element={
            <ProtectedRoute>
              <ProfileSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.business}
          element={
            <ProtectedRoute>
              <BusinessSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.password}
          element={
            <ProtectedRoute>
              <PasswordSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.security}
          element={
            <ProtectedRoute>
              <SecuritySettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.bankAccounts}
          element={
            <ProtectedRoute>
              <BankAccountSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.cryptoAddresses}
          element={
            <ProtectedRoute>
              <CryptoAddressSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.team}
          element={
            <ProtectedRoute>
              <TeamSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.compliance}
          element={
            <ProtectedRoute>
              <ComplianceSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.developers}
          element={
            <ProtectedRoute>
              <DeveloperSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.developerKeys}
          element={
            <ProtectedRoute>
              <DeveloperKeys />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.developerWebhooks}
          element={
            <ProtectedRoute>
              <DeveloperWebhooks />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.developerWebhookHistory}
          element={
            <ProtectedRoute>
              <DeveloperWebhookHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.developerTools}
          element={
            <ProtectedRoute>
              <DeveloperTools />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.notifications}
          element={
            <ProtectedRoute>
              <NotificationSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path={route.settings.system}
          element={
            <ProtectedRoute>
              <SystemSettings />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
    </>
  );
};
