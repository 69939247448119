import React from 'react';
import { AddNewFiatRecipient } from '../../components/AddNewFiatRecipient';
import { WithdrawalAccountDto } from '../../../typings';

type AddType = 'DEPOSIT' | 'WITHDRAWAL';

type AddBankAccountModal = {
  type?: AddType;
  onAccountAdd?(type: AddType, account: WithdrawalAccountDto): void;
};

export const AddBankAccountModal = (props: AddBankAccountModal) => {
  const { type = 'WITHDRAWAL', onAccountAdd = () => {} } = props;

  return <AddNewFiatRecipient mode="save-only" close={(account) => onAccountAdd(type, account as WithdrawalAccountDto)} />;
};
