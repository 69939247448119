import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Cleave from 'cleave.js/react';
import { toMethodLabel, WithdrawalProps } from './common';
import { baseStore } from '../../../../store';
import { Button } from '../../html/Button';
import { Input } from '../../html/Input';
import { CurrencyDefinition, WalletAsset, WithdrawalMethodDto, WithdrawalMethodType } from '../../../../typings';
import Gap from '../../Gap';
import '../styles.scss';
import { formatCurrency, handleRequestErrors, normaliseAmount } from '../../../../utils';
import { noWithdrawalDecimals } from '../../../../constants';
import * as withdrawalApi from '../../../../api/withdrawal';
import { applyFee } from '../../../../utils/math';
import { LocationSelect } from '../../LocationSelect';
import { ConditionalElement } from '../../ConditionalElement';

export const Stage1 = (props: WithdrawalProps) => {
  const { request, goToNext = () => {}, updateRequest = () => {} } = props;

  const [memo, setMemo] = useState('');
  const [amount, setAmount] = useState(0);
  const { rates, wallets } = baseStore.getState();
  const [currency, setCurrency] = useState<CurrencyDefinition>();
  const [sourceWallet, setSourceWallet] = useState<WalletAsset>();
  const [countryCode, setCountryCode] = useState<string | null>();
  const [sourceCurrency, setSourceCurrency] = useState<{ label: string; value: string }>();
  const [sourceCurrencyOptions, setSourceCurrencyOptions] = useState<any[]>([]);
  const [transferMethodOptions, setTransferMethodOptions] = useState<any[]>([]);
  const [feeMethod, setFeeMethod] = useState<WithdrawalMethodDto | null>();
  const [feeMethods, setFeeMethods] = useState<WithdrawalMethodDto[]>([]);
  const [methodId, setMethodId] = useState<{ label: string; value: WithdrawalMethodType } | null>();
  const [selectCountries, setSelectCountries] = useState<string[]>([]);
  const [balanceErrorMessage, setBalanceErrorMessage] = useState('');

  const updateCountryDefault = (currencyCode: string) => {
    withdrawalApi
      .getChannels('FIAT')
      .then(({ channels }) => {
        const currencyChannels = channels?.filter((channel) => channel.currency === currencyCode);
        const countries = currencyChannels?.map((channel) => channel.country);
        setSelectCountries(countries);

        if (countries.length === 1) {
          setCountryCode(countries[0]);
          updateRequest({ country: countries[0] });
        }
      })
      .catch(handleRequestErrors);
  };

  useEffect(() => {
    const currOptions = wallets!
      .filter(
        ({ currency }) =>
          currency.type === 'FIAT' &&
          (currency.activities.includes('WITHDRAW') || currency.networks?.some((network) => network.activities.includes('WITHDRAW'))),
      )
      .map((wallet) => ({ label: wallet.currency.name, value: wallet.currency.code }));

    setSourceCurrencyOptions(currOptions);
    setSourceCurrency(currOptions.find((option) => option.value === props.currency?.code));
  }, []);

  useEffect(() => {
    const currencyCode = sourceCurrency?.value || props.currency.code;
    const curr = rates.currencies.find(({ code }) => code === currencyCode);

    setCurrency(curr);
    setSourceWallet(wallets?.find((wallet) => wallet.currency.code === currencyCode));
    updateRequest({ amount: 0, currency: curr?.code });
  }, [props.currency.code, sourceCurrency?.value]);

  useEffect(() => {
    if (!currency) {
      return;
    }

    updateCountryDefault(currency.code);
  }, [currency?.code]);

  useEffect(() => {
    if (!currency?.code || !countryCode) {
      return;
    }

    withdrawalApi
      .getMethods(currency.code, currency.code, countryCode)
      .then(({ methods }) => {
        setFeeMethods(methods);
        setTransferMethodOptions(methods.map(({ type }) => ({ label: toMethodLabel(type), value: type })));
      })
      .catch(handleRequestErrors);
  }, [currency?.code, countryCode]);

  useEffect(() => {
    const method = feeMethods.find((meth) => meth.type === methodId?.value);

    if (method) {
      setFeeMethod(method);
      updateRequest({ feeAmount: applyFee(amount || 0, method.fee) });
    }
  }, [amount, methodId?.value]);

  useEffect(() => {
    setBalanceErrorMessage(amount + (request?.feeAmount || 0) > sourceWallet?.balance! ? 'Insufficient funds available' : '');
  }, [request?.feeAmount, amount]);

  return (
    <>
      <div className="head">
        <h4>Some basic information about your transfer</h4>
      </div>

      <div className="content">
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            goToNext();
          }}
        >
          <label htmlFor="w-currency">Select a Currency</label>
          <Gap v={0.5} />
          <Select
            id="w-currency"
            name="sourceCurrency"
            options={sourceCurrencyOptions}
            onChange={(ev: any) => {
              setFeeMethod(null);
              setMethodId(null);
              setCountryCode(null);
              setAmount(0);
              setSourceCurrency(ev);
              updateRequest({
                amount: 0,
                currency: ev.value,
                country: undefined,
                methodId: undefined,
              });
            }}
            value={sourceCurrency}
            classNamePrefix="custom-select"
          />
          {sourceCurrency && currency ? (
            <small className="text-muted">
              Available: {formatCurrency(currency?.type!, sourceWallet?.balance || 0, sourceCurrency?.value!, currency?.decimals!)}{' '}
            </small>
          ) : null}

          <Gap v={1} />

          <ConditionalElement
            condition={selectCountries.length > 1}
            element={
              <>
                <LocationSelect
                  select={['countries']}
                  filterCountries={selectCountries}
                  onChange={(ev) => {
                    const country = ev.country?.iso;
                    setCountryCode(country);

                    if (country !== request?.country) {
                      updateRequest({ methodId: undefined });
                    }

                    updateRequest({ country });
                  }}
                  gapBottom={1}
                />
              </>
            }
          />

          <label htmlFor="w-currency">Transfer method</label>
          <Gap v={0.5} />
          <Select
            id="w-method"
            name="methodId"
            options={transferMethodOptions}
            onChange={(ev: any) => {
              setMethodId(ev);
              updateRequest({ methodId: ev.value });
            }}
            value={methodId}
            classNamePrefix="custom-select"
          />
          {currency && feeMethod ? (
            <small className="text-muted">
              Fee:{' '}
              {
                // prettier-ignore
                feeMethod?.fee.flat ?
                  formatCurrency(currency.type, feeMethod.fee.flat, currency.code, sourceWallet?.currency.decimals) :
                    feeMethod?.fee.percent ? `${feeMethod?.fee.percent}%` :
                      formatCurrency(currency.type, 0, currency.code, sourceWallet?.currency.decimals)
              }
            </small>
          ) : null}

          <Gap v={1} />

          {currency ? (
            <>
              <div className="row">
                <div className="col">
                  <label>Enter Amount</label>
                </div>
                <div className="col-auto">
                  <a
                    className="cursor-pointer"
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      const balance = sourceWallet?.balance!;
                      const newAmount = normaliseAmount(sourceWallet?.currency.withdrawDecimals!, balance - (request?.feeAmount || 0));
                      const amountValue = newAmount <= 0 ? 0 : newAmount;
                      setAmount(amountValue);
                      updateRequest({ amount: amountValue });
                    }}
                  >
                    Use Max
                  </a>
                </div>
              </div>
              <Gap v={0.5} />
              <div className="cleave-currency-wrap">
                <div className="symbol">{currency.symbol}</div>

                <Cleave
                  required
                  name="amount"
                  className="form-control font-weight-bold"
                  placeholder="0.00"
                  autoComplete="off"
                  value={amount}
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalScale: noWithdrawalDecimals.includes(currency.code) ? 0 : currency.decimals,
                  }}
                  onChange={(ev) => {
                    const amountValue = Number(ev.target.value.replace(/[^\d.-]/g, ''));
                    setAmount(amountValue);
                    updateRequest({ amount: amountValue });
                  }}
                />
              </div>
              <Gap v={0.5} />
              {currency ? (
                <span>
                  Fee: {formatCurrency(currency?.type, request?.feeAmount || 0, sourceCurrency?.value!, sourceWallet?.currency.decimals)}
                </span>
              ) : null}
            </>
          ) : null}

          <Gap v={1} />

          <Input
            required={true}
            autoComplete="off"
            id="w-memo"
            type="text"
            name="memo"
            label="Memo"
            placeholder="Memo. E.g Settlement"
            onChange={(ev) => {
              const memoValue = ev.target.value;
              setMemo(memoValue);
              updateRequest({ memo: memoValue });
            }}
            gapbottom={1}
          />

          <ConditionalElement
            condition={!!balanceErrorMessage}
            element={<div className="color-fd0000 text-center">{balanceErrorMessage}</div>}
          />

          <Gap v={1} />

          <Button disabled={!(currency && amount > 0 && memo)} type="submit" className="btn btn-primary full-width" text="Next" />
        </form>
      </div>
    </>
  );
};
