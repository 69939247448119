import React from 'react';
import Gap from '../Gap';
import Select from 'react-select';
import { RecipientProps } from './common';
import { Input } from '../html/Input';
import { Button } from '../../components/html/Button';
import { ConditionalElement } from '../ConditionalElement';

export const Stage2 = (props: RecipientProps) => {
  const { mode, recipient, goToPrevious = () => {}, goToNext = () => {}, updateRecipient = () => {} } = props;

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        goToNext();
      }}
    >
      <div className="recipient-account-add">
        <a onClick={() => goToPrevious()} className="back-button"></a>

        <div className="head">
          <h4>Some information about your {mode === 'save-only' ? 'new account' : 'recipient account'} details</h4>
        </div>

        <Gap v={1} />

        <input type="hidden" name="bankId" value={`${recipient?.country}::000`} />

        <label htmlFor="w-currency">Beneficiary type</label>
        <Gap v={0.5} />
        <Select
          required
          id="w-beneficiary-type"
          name="beneficiaryType"
          options={[
            { label: 'Individual', value: 'INDIVIDUAL' },
            { label: 'Business', value: 'BUSINESS' },
          ]}
          onChange={(ev) => updateRecipient({ beneficiaryType: ev?.value })}
          classNamePrefix="custom-select"
        />
        <Gap v={1} />

        <Input
          required
          label="Account Name"
          name="accountName"
          placeholder="Eg. Company Corp."
          gapbottom={1}
          onChange={(ev) => updateRecipient({ accountName: ev.target.value })}
        />

        <Input
          required
          label="Account number"
          name="accountNumber"
          placeholder="Ex. 2004567891"
          gapbottom={1}
          onChange={(ev) => updateRecipient({ accountNumber: ev.target.value })}
        />

        <ConditionalElement
          condition={['EUR'].includes(recipient?.currency!)}
          element={
            <Input
              required
              label="IBAN"
              name="iban"
              placeholder="Ex. EX7614607003655302109367386"
              gapbottom={1}
              onChange={(ev) => updateRecipient({ iban: ev.target.value })}
            />
          }
        />

        <ConditionalElement
          condition={['GBP'].includes(recipient?.currency!)}
          element={
            <Input
              required
              label="Sort code"
              name="sortCode"
              placeholder="Ex. 1234567"
              gapbottom={1}
              onChange={(ev) => updateRecipient({ sortCode: ev.target.value })}
            />
          }
        />

        <Input
          required
          label="Bank name"
          name="bankName"
          placeholder="Eg. Standard Trust Bank"
          gapbottom={1}
          onChange={(ev) => updateRecipient({ type: 'BANK', bankName: ev.target.value })}
        />

        <Input
          label="Routing number"
          name="routingNumber"
          placeholder="Eg. 1100223476"
          gapbottom={1}
          onChange={(ev) => updateRecipient({ routingNumber: ev.target.value })}
        />

        <Gap v={1} />

        <Button className="btn btn-primary full-width" text="Next" />
      </div>
    </form>
  );
};
